import Earth from '../../images/Earth'
import File from '../../images/File'
import Key from '../../images/Key'
import List from '../../images/List'
import Loc from '../../images/Loc'
import MailAddress from '../../images/MailAddress'
import MailLock from '../../images/MailLock'
import Port from '../../images/Port'
import Tech from '../../images/Tech'

export const CardData = {
  DNS: {
    title: 'DNS Records',
    link: `/exposures-explore/DNS Records`,
    count: 100,
    icon: <List className='svg-icon svg-icon-5x image_change_theme2' />,
    footer: [
      {
        name: 'A Records',
        value: '113',
        icon: <List className='svg-icon svg-icon-4x image_change_theme2' />,
      },
      {
        name: 'CNAME Records',
        value: '36',
        icon: <List className='svg-icon svg-icon-4x image_change_theme2' />,
      },
    ],
  },
  port: {
    title: 'Ports',
    link: `/exposures-explore/Ports`,
    count: 100,
    icon: <Port className='svg-icon svg-icon-5x image_change_theme2' />,
    chart: [
      {
        title: 'Close',
        count: 444,
        data: [1, 40, 60, 80],
      },
      {
        title: 'Open',
        count: 354,
        data: [100, 4, 6, 8],
      },
    ],
    footer: [
      {
        name: 'Domains',
        value: '113',
        icon: <Earth className='svg-icon svg-icon-4x image_change_theme2' />,
      },
      {
        name: 'IP',
        value: '36',
        icon: <Loc className='svg-icon svg-icon-4x image_change_theme2' />,
      },
    ],
  },
  ssl: {
    link: `/exposures-explore/SSL`,
    icon: <Key className='svg-icon svg-icon-5x image_change_theme2' />,
    title: 'SSL',
    count: 100,
  },
  smtp: {
    link: `/exposures-explore/SMTP`,
    icon: <MailLock className='svg-icon svg-icon-5x image_change_theme2' />,
    title: 'SMTP',
    count: 100,
  },
  mail: {
    link: `/exposures-explore/Email Address`,
    icon: <MailAddress className='svg-icon svg-icon-5x image_change_theme2' />,
    title: 'Email Address',
    count: 100,
  },
  technologies: {
    link: `/exposures-explore/Technologies`,
    icon: <Tech className='svg-icon svg-icon-5x image_change_theme2' />,
    title: 'Technologies',
    count: 100,
  },
  leaked: {
    link: `/exposures-explore/Leaked Data`,
    icon: <File className='svg-icon svg-icon-5x image_change_theme2' />,
    title: 'Leaked Data',
    count: 100,
  },
}
