/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {memo, useEffect, useRef} from 'react'
import ApexCharts, {ApexOptions} from 'apexcharts'
import {getCSS, getCSSVariableValue} from '../../../../_metronic/assets/ts/_utils'
import InfoIcon from '../../images/InfoIcon'
import {useThemeMode} from '../../../../_metronic/partials/index'
import {Tooltip} from 'react-tooltip'
import { useIntl } from 'react-intl'

const WAFSecurity = memo(({color = 'warning', data = [1, 1, 1]}: {color?: string; data: number[]}) => {
  const chartRef = useRef(null)
  const intl = useIntl()
  const {mode} = useThemeMode()
  const refreshChart = () => {
    if (!chartRef.current) {
      return
    }

    const height = parseInt(getCSS(chartRef.current, 'height'))
    const labelColor = getCSSVariableValue('--bs-gray-800')
    const baseColor = getCSSVariableValue('--bs-' + color)
    const lightColor = getCSSVariableValue('--bs-' + color + '-light')

    const chart = new ApexCharts(
      chartRef.current,
      getChartOptions(height, labelColor, baseColor, lightColor, data)
    )
    if (chart) {
      chart.render()
    }

    return chart
  }

  useEffect(() => {
    const chart = refreshChart()

    return () => {
      if (chart) {
        chart.destroy()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartRef, mode])
  function getChartOptions (
    height: number,
    labelColor: string,
    baseColor: string,
    lightColor: string,
    data: number[]
  ): ApexOptions {
    const options: ApexOptions = {
      series: [
        {
          name: intl.formatMessage({id: 'Bypassed Request'}),
          data: data,
        },
      ],
      chart: {
        fontFamily: 'inherit',
        type: 'area',
        height: height,
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: false,
        },
        sparkline: {
          enabled: true,
        },
      },
      plotOptions: {},
      legend: {
        show: false,
      },
      dataLabels: {
        enabled: false,
      },
      fill: {
        type: 'solid',
        opacity: 1,
      },
      stroke: {
        curve: 'smooth',
        show: true,
        width: 3,
        colors: [baseColor],
      },
      xaxis: {
        categories: ['Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul'],
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        labels: {
          show: false,
          style: {
            colors: labelColor,
            fontSize: '12px',
          },
        },
        crosshairs: {
          show: false,
          position: 'front',
          stroke: {
            color: '#E4E6EF',
            width: 1,
            dashArray: 3,
          },
        },
        tooltip: {
          enabled: false,
        },
      },
      yaxis: {
        min: 0,
        max: 80,
        labels: {
          show: false,
          style: {
            colors: labelColor,
            fontSize: '12px',
          },
        },
      },
      states: {
        normal: {
          filter: {
            type: 'none',
            value: 0,
          },
        },
        hover: {
          filter: {
            type: 'none',
            value: 0,
          },
        },
        active: {
          allowMultipleDataPointsSelection: false,
          filter: {
            type: 'none',
            value: 0,
          },
        },
      },
      tooltip: {
        style: {
          fontSize: '12px',
        },
        y: {
          formatter: function (val: number) {
            return '$' + val + ' thousands'
          },
        },
      },
      colors: [lightColor],
      markers: {
        colors: [lightColor],
        strokeColors: [baseColor],
        strokeWidth: 3,
      },
    }
    return options
  }
  return (
    <div className={`card h-100 mh-250px`}>
      <div
        className={`w-100 h-50px p-5 fw-bolder d-flex justify-content-center align-items-center text-body bg-secondary rounded-top`}
      >
        {intl.formatMessage({id: 'WAF Security Assessment'})}
        <span data-tooltip-id='my-tooltip-WAF' data-tooltip-content={intl.formatMessage({id: "WAF Security Assessment tooltip"})}>
          <InfoIcon />
        </span>
        <Tooltip id='my-tooltip-WAF' style={{maxWidth:250,fontSize:12 ,textAlign:'center'}}/>
      </div>

      {/* begin::Body */}
      <div className='card-body d-flex flex-column p-0'>
        <div
          ref={chartRef}
          className='statistics-widget-3-chart card-rounded-bottom p-10 pt-6'
          style={{height: '80px'}}
        ></div>

        <div className='h-50 d-flex flex-row align-items-center ps-8 mt-3'>
          <span className={`fw-bolder`} style={{fontSize: '3em'}}></span>
          <span className={`fw-bold mx-3`}>
            {intl.formatMessage({id: 'FortiWeb (Fortinet) WAF'})}
           </span>
        </div>
      </div>
      {/* end::Body */}
    </div>
  )
})

export default WAFSecurity


