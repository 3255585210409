import {useState} from 'react'

import './toltip.scss'
import 'react-multi-date-picker/styles/backgrounds/bg-dark.css'
import CreateModal from './CreateModal'
// import Reloade from '../../images/Reloade'
import Pause from '../../images/Pause'
import Trash from '../../images/Trash'
import axios from 'axios'
import {Alert} from 'react-bootstrap'
import {KTSVG} from '../../../../_metronic/helpers'
import ConvertDate from '../../../../Utils/ConvertDate'
import Edit from '../../images/Edit'
import useErrorHandling from '../../../../Utils/useErrorHandling'
import EditModal from './EditModal'
import {useIntl} from 'react-intl'
import Warning from '../../images/Warning'
import Play from '../../images/Play'
import Check from '../../images/Ckeck'
interface Props {
  setFlipCard: React.Dispatch<React.SetStateAction<number|null>>
  flipCard: number|null
  scanData: any
  dagsData: any
  getData: (page?: number) => Promise<void>
}
const ScanTable = ({scanData, dagsData, getData, setFlipCard ,flipCard}: Props) => {
  const [loading, setLoading] = useState(false)
  const [show, setShow] = useState(false)
  const [del, setDel] = useState<null | number | string>(null)
  const [stop, setStop] = useState<null | number | string>(null)
  const [editId, setEditId] = useState<null | number | string>(null)
  const [errShow, setErrShow] = useState('')
  const errorHandling = useErrorHandling()
  const intl = useIntl()
  async function stopScan (id: string) {
    if (!(process.env.REACT_APP_MODE === 'demo')) {
      setStop(id)
      await axios
        .post(`${process.env.REACT_APP_API_ENDPOINT}/scan/${id}/stop/`, {
          status: 'stop',
        })
        .then((res) => {
          errorHandling(res.status)
          if (res.status >= 200 && res.status < 300) {
            getData()
          } else {
            console.log(res?.data?.detail)
            setErrShow(
              res?.data?.detail || intl.formatMessage({id: 'Sorry, an error has occurred'})
            )
          }
        })
        .catch((e) => {
          setErrShow(e.message || intl.formatMessage({id: 'Sorry, an error has occurred'}))
        })
        .finally(() => {
          setStop(null)
        })
    }
  }
  async function deleteScan (id: string) {
    if (!(process.env.REACT_APP_MODE === 'demo')) {
      setDel(id)
      await axios
        .delete(`${process.env.REACT_APP_API_ENDPOINT}/scan/${id}/`)
        .then((res) => {
          errorHandling(res.status)
          if (res.status >= 200 && res.status < 300) {
            getData()
          } else {
            setErrShow(
              res?.data?.detail || intl.formatMessage({id: 'Sorry, an error has occurred'})
            )
          }
        })
        .catch((e) => {
          setErrShow(e.message || intl.formatMessage({id: 'Sorry, an error has occurred'}))
        })
        .finally(() => {
          setDel(null)
        })
    }
  }
  return (
    <>
      {/* start Alert */}
      <div
        className=' d-flex flex-column align-items-center position-absolute top-10 start-auto right-auto '
        style={{zIndex: 100, top: -45}}
      >
        <Alert show={show} variant='success' style={{width: 'fit-content'}}>
          <Alert.Heading
            className='w-100 d-flex gap-10  justify-content-between '
            style={{width: 'fit-content'}}
          >
            Scan created successfully
            <div onClick={() => setShow(false)}>
              <KTSVG
                path='/media/icons/duotune/arrows/arr061.svg'
                className='svg-icon-2 image_change_theme2'
              />
            </div>
          </Alert.Heading>
        </Alert>
        <>
          <Alert show={!!errShow} variant='danger' style={{width: 'fit-content'}}>
            <Alert.Heading
              className='w-100  d-flex gap-10  justify-content-between '
              style={{width: 'fit-content'}}
            >
              {errShow}
              <div onClick={() => setErrShow('')}>
                <KTSVG
                  path='/media/icons/duotune/arrows/arr061.svg'
                  className='svg-icon-2 image_change_theme2'
                />
              </div>
            </Alert.Heading>
          </Alert>
        </>
      </div>
      {/* end Alert */}
      <div className='w-100 h-100'>
        <div className={`card card-body h-100 bg-body rounded-bottom-0 p-3`}>
          {/* start title */}
          <div className='w-100 pe-3 d-flex h-100px flex-row justify-content-between align-items-start pt-2'>
            <div className='w-100  d-flex  mb-10 justify-content-between align-items-center'>
              <h3 className=' '>{intl.formatMessage({id: 'Scan'})}</h3>
              <button
                type='button'
                className='btn btn-primary'
                data-bs-toggle='modal'
                data-bs-target='#kt_modal_1'
                disabled={loading}
                onClick={() => {
                  setErrShow('')
                  setShow(false)
                }}
              >
                {loading
                  ? `${intl.formatMessage({id: 'Wait for create Scan'})} ...`
                  : intl.formatMessage({id: 'Create New Scan'})}
              </button>
            </div>
          </div>
          {/* start body */}
          {/* start titles */}
          {/* <div className='w-100 m-0 pe-3 '>
            <div className='w-100 d-flex fs-5 fw-bold bg-gray-400 rounded-1 mb-4 p-2 pt-1 pb-1 justify-content-between align-items-center'>
              <div className='w-25 p-1 h-auto'>{intl.formatMessage({id: 'Target'})}  </div>
              <div className='w-25 p-1 ps-2 ms-5 h-auto'>{intl.formatMessage({id: 'Workflow'})}  </div>
              <div className='w-300px p-1 h-auto d-flex '>{intl.formatMessage({id: 'Description'})}</div>
              <div className='w-100px d-flex justify-content-center p-1 ms-2 h-auto'>{intl.formatMessage({id: 'Rate'})}</div>
              <div className='w-200px p-1 ms-2 h-auto d-flex justify-content-center'> {intl.formatMessage({id: 'Started'})}</div>
              <div className='w-200px p-1 ms-2 h-auto d-flex justify-content-center'> {intl.formatMessage({id: 'Status'})}</div>
              <div className='w-200px p-1 ms-2 h-auto d-flex justify-content-center pe-3'>
                {intl.formatMessage({id: 'Action'})}
                
              </div>
            </div>
          </div> */}
          {/* end titles */}
          <div className={`w-100 h-100 overflow-visible   m-0 `}>
            {scanData?.map((item: any) => {
              return (
                <div
                  key={item?.id}
                  className={`w-100 m-0 p-0 h-125px bg-gray-200 rounded-1 d-flex justify-content-center align-items-center mb-3 hover_Costume
                    ${item.id === flipCard && 'selected_Costume'}
                    `}

                  onClick={() => {
                    setFlipCard(item.id)
                  }}
                >
                  <div className='w-100 d-flex p-2 pt-1 pb-1 justify-content-between align-items-center'>
                    <div
                      className={`w-3px h-100px rounded ${
                        item?.status === 'failed'
                          ? 'bg-danger'
                          : item?.status === 'running'
                          ? ' bg-primary'
                          : 'bg-success'
                      }`}
                    ></div>
                    <div
                      className='w-25 mw-25 h-100px d-flex flex-column justify-content-between align-items-center p-1 h-auto'
                      style={{maxWidth: 130}}
                    >
                      <div className={`w-100 h-auto text-primary fs-5`}>{item?.target}</div>
                      <div className='w-100 h-auto d-flex flex-column gap-1 '>
                        <span className='text-gray-800'>
                          {intl.formatMessage({id: 'Workflow'})}
                        </span>
                        <span className='fs-6 text-primary'>
                          {item?.workflow_id === 'passive_scan_once'
                            ? 'Passive Scan'
                            : item?.workflow_id === 'asset_discovery'
                            ? 'Asset Discovery'
                            : 'Active Scan'}
                        </span>
                      </div>
                    </div>
                    <div
                      className='w-75 mw-330px h-100px d-flex flex-column justify-content-between p-1 h-auto'
                      style={{maxWidth: 330}}
                    >
                      <div className='d-flex gap-3 justify-content-between px-2'>
                        <div className='w-50px h-auto d-flex flex-column gap-1'>
                          <span className='text-gray-800'>{intl.formatMessage({id: 'Rate'})}</span>
                          <div className=' d-flex  align-items-center p-1 h-auto'>{item?.rate}</div>
                        </div>

                        <div className=' h-auto d-flex flex-column gap-1 '>
                          <span className='text-gray-800'>
                            {intl.formatMessage({id: 'Started'})}
                          </span>
                          <div className=' h-auto d-flex justify-content-center p-1'>
                            <ConvertDate date={item?.start_time} />
                          </div>
                        </div>
                        <div style={{minWidth: 108}}>
                          <div
                            className=' p-1 px-2 d-flex rounded justify-content-center gap-2 bg-body align-items-center '
                            style={{height: 'fit-content', width: 'fit-content'}}
                          >
                            {item?.status === 'failed' ? (
                              <Warning className='svg-icon-danger svg-icon svg-icon-2x' />
                            ) : item?.status === 'running' ? (
                              <Play className='svg-icon-primary svg-icon svg-icon-2x' />
                            ) : (
                              <Check className='svg-icon-success svg-icon svg-icon-2x' />
                            )}
                            <span className=' text-capitalize'>{item?.status}</span>
                          </div>
                        </div>
                      </div>
                      <div className='px-2 w-100 d-flex flex-column gap-1'>
                        <span className='text-gray-800'>
                          {intl.formatMessage({id: 'Description'})}
                        </span>
                        <div
                          className=' w-100  h-auto  '
                          style={{
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            width: '90%',
                          }}
                        >
                          {item?.description || '_'}
                        </div>
                      </div>
                    </div>
                    <div className='w-30px p-1 ms-2 h-auto d-flex flex-column justify-content-center gap-2 pe-3'>
                      <button
                        className={`col-auto border border-0 text-white bg-transparent p-0 m-0`}
                        type='button'
                        data-bs-toggle='modal'
                        data-bs-target='#kt_modal_edit'
                        onClick={() => {
                          setErrShow('')
                          setEditId(item.id)
                        }}
                      >
                        <Edit className='svg-icon svg-icon-2x svg-icon-primary' />
                      </button>
                      <button
                        className={`col-auto border border-0 bg-transparent text-white bg-gray-200 p-0 m-0`}
                        disabled={stop === item.id}
                        onClick={() => {
                          stopScan(item.id)
                        }}
                      >
                        <Pause
                          className={`svg-icon-2x ${
                            stop === item.id ? 'image_change_theme2' : 'svg-icon-warning'
                          }`}
                        />
                      </button>
                      <button
                        className={`col-auto border border-0 bg-transparent text-white bg-gray-200 p-0 m-0`}
                        disabled={del === item.id}
                        onClick={() => {
                          deleteScan(item.id)
                        }}
                      >
                        <Trash
                          className={`svg-icon-2x ${
                            del === item.id ? 'image_change_theme2' : 'svg-icon-danger'
                          }`}
                        />
                      </button>
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </div>
      {/* <!-- Modal--> */}
      <CreateModal
        dags={dagsData}
        getData={getData}
        setErrShow={setErrShow}
        setShow={setShow}
        setLoading={setLoading}
      />
      <EditModal getData={getData} setErrShow={setErrShow} id={editId} setId={setEditId} />
    </>
  )
}

export default ScanTable
