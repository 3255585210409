import React from 'react'
import Earth from '../../images/Earth'
import Key from '../../images/Key'
import List from '../../images/List'
import Loc from '../../images/Loc'
import MailAddress from '../../images/MailAddress'
import MailLock from '../../images/MailLock'
import Port from '../../images/Port'
import Tech from '../../images/Tech'
import Iphone from '../../images/Iphone'
import File from '../../images/File'
export  const ExploreSidebar = [
    {
        name:'Domains',
        icon:<Earth className='svg-icon svg-icon-4x image_change_theme2' />,
    },
    {
        name:'IP',
        icon:<Loc className='svg-icon svg-icon-4x image_change_theme2' />
    },
    {
        name:'Applications',
        icon:<Iphone className='svg-icon svg-icon-4x image_change_theme2' />
    },
    {
        name:'DNS Records',
        icon:<List className='svg-icon svg-icon-4x image_change_theme2' />
    },
    {
        name:'Ports',
        icon:<Port className='svg-icon svg-icon-4x image_change_theme2' />
    },
    {
        name:'SSL',
        icon:<Key className='svg-icon svg-icon-4x image_change_theme2' />
    },
    {
        name:'SMTP',
        icon:<MailLock className='svg-icon svg-icon-4x image_change_theme2' />
    },
    {
        name:'Email Address',
        icon:<MailAddress className='svg-icon svg-icon-4x image_change_theme2' />
    },
    {
        name:'Technologies',
        icon:<Tech className='svg-icon svg-icon-4x image_change_theme2' />
    },
    {
        name:'Leaked Data',
        icon:<File className='svg-icon svg-icon-4x image_change_theme2' />
    },


]