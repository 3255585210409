import React from 'react'
import CardProvider from './CardProvider'
import { overview } from './MockData'

export default function Overview() {
  return (
    <div className='d-flex gap-2 w-100'>
    {overview.map((item, ind) => (
      <CardProvider
        key={ind}
        title={item.title}
        className=' w-auto px-5 text-nowrap border-1 border-gray-300'
      >
        <div>
          <h1 style={{fontSize: 40}} className={item.class}>
            <strong>{item.value}</strong>
          </h1>
        </div>
      </CardProvider>
    ))}
  </div>
  )
}
