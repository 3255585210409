export const  DnsData =[
    {
        record:'www.example.com',
        value:'76.223.54.146',
        type:'A',
        domain:'example.com',
        detected:"2024-10-11T16:20:45.680682Z"
    },
    {
        record:'www.example.com',
        value:'76.223.54.146',
        type:'A',
        domain:'example.com',
        detected:"2024-10-11T16:20:45.680682Z"
    },
    {
        record:'www.example.com',
        value:'76.223.54.146',
        type:'A',
        domain:'example.com',
        detected:"2024-10-11T16:20:45.680682Z"
    },
    {
        record:'www.example.com',
        value:'76.223.54.146',
        type:'A',
        domain:'example.com',
        detected:"2024-10-11T16:20:45.680682Z"
    },
    {
        record:'www.example.com',
        value:'76.223.54.146',
        type:'A',
        domain:'example.com',
        detected:"2024-10-11T16:20:45.680682Z"
    },
    {
        record:'www.example.com',
        value:'76.223.54.146',
        type:'A',
        domain:'example.com',
        detected:"2024-10-11T16:20:45.680682Z"
    },
    {
        record:'www.example.com',
        value:'76.223.54.146',
        type:'A',
        domain:'example.com',
        detected:"2024-10-11T16:20:45.680682Z"
    },
    {
        record:'www.example.com',
        value:'76.223.54.146',
        type:'A',
        domain:'example.com',
        detected:"2024-10-11T16:20:45.680682Z"
    },
    {
        record:'www.example.com',
        value:'76.223.54.146',
        type:'A',
        domain:'example.com',
        detected:"2024-10-11T16:20:45.680682Z"
    },
    {
        record:'www.example.com',
        value:'76.223.54.146',
        type:'A',
        domain:'example.com',
        detected:"2024-10-11T16:20:45.680682Z"
    },
]