import {ApexOptions} from 'apexcharts'
import React, {memo} from 'react'
import Chart from 'react-apexcharts'
import {useIntl} from 'react-intl'

const DonutChart = memo(({data = [43, 33, 1, 1, 25]}: {data: number[]}) => {
  const intl = useIntl()
  const options: ApexOptions = {
    labels: [
      `${intl.formatMessage({id: 'Grade'})}: A`,
      `${intl.formatMessage({id: 'Grade'})}: B`,
      `${intl.formatMessage({id: 'Grade'})}: C`,
      `${intl.formatMessage({id: 'Grade'})}: D`,
      `${intl.formatMessage({id: 'Grade'})}: F`,
    ],
    colors: ['#02694a', '#255a8f', '#9c8705', '#c02407', '#75041e'],
    legend: {
      show: true,
      showForSingleSeries: false,
      showForNullSeries: true,
      showForZeroSeries: true,
      fontSize: '14px',
      labels: {
        colors: '#92929F',
      },
    },
    chart: {
      type: 'donut',
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: 'bottom',
          },
        },
      },
    ],
    stroke: {
      show: true,
      curve: 'smooth',
      lineCap: 'butt',
      colors: ['#ff003300'],
      width: 2,
      dashArray: 0,
    },
    plotOptions: {
      pie: {
        offsetY: 0,
        customScale: 1,
        donut: {
          size: '50%',
        },
      },
    },
  }

  const series = data.map((item) => {
    if (!!item) {
      return item
    } else {
      return 1
    }
  })
  console.log(series)
  return (
    <>
      <Chart height={220} width={'100%'} options={options} series={series} type='donut' />
    </>
  )
})

export default DonutChart
