import React from 'react';
import { MapContainer, TileLayer, Marker } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
const customIcon = L.icon({
    iconUrl: '/ip.png', // Path to your custom icon
    iconSize: [32, 32], // Size of the icon
    iconAnchor: [16, 32], // Anchor point for the icon
  });
  
export default function Map({lat=51.649 , lng=-0.09}:{lat:number , lng:number}) {
    const position = {lat:51.505, lng:-0.09}

  return (
    <MapContainer center={{lat, lng}} zoom={13} scrollWheelZoom={false} style={{width:'100%' , height:300 , borderRadius:"20px"}}>
  <TileLayer
    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
  />
  <Marker icon={customIcon} position={{lat, lng}}>

  </Marker>
  </MapContainer>
  )
}
