import React, {memo} from 'react'

const Pause = memo(({className = '', svgClassName = 'mh-50px'}: {className?: string; svgClassName?: string}) => {
  return (
    <span className={`svg-icon ${className}`}>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='24px'
        height='24px'
        viewBox='0 0 24 24'
        version='1.1'
        className={svgClassName}
      >
        <defs />
        <g stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'>
          <rect x='0' y='0' width='24' height='24' />
          <path
            d='M8,6 L10,6 C10.5522847,6 11,6.44771525 11,7 L11,17 C11,17.5522847 10.5522847,18 10,18 L8,18 C7.44771525,18 7,17.5522847 7,17 L7,7 C7,6.44771525 7.44771525,6 8,6 Z M14,6 L16,6 C16.5522847,6 17,6.44771525 17,7 L17,17 C17,17.5522847 16.5522847,18 16,18 L14,18 C13.4477153,18 13,17.5522847 13,17 L13,7 C13,6.44771525 13.4477153,6 14,6 Z'
            fill='#000000'
          />
        </g>
      </svg>
    </span>
  )
})
export default Pause
