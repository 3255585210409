import {memo} from 'react'
import {useNavigate} from 'react-router-dom'

import Trash from '../../images/Trash'
import Edit from '../../images/Edit'
import ConvertDate from '../../../../Utils/ConvertDate'
import { useIntl } from 'react-intl'
interface Table {
  id: string
  name: string
  description: string
  created: string
}
const WorkflowTable = memo(({workflowData}: {workflowData: Table[]}) => {
  const navigate = useNavigate()
  const intl = useIntl()
  return workflowData ? (
    <div className='w-100 h-100'>
      <div className={`card card-body h-100 bg-body rounded-bottom-0`}>
        {/* start title */}
        <div className='w-100 pe-3 d-flex h-100px flex-row justify-content-between align-items-start pt-2'>
          <h3 className=' '>{intl.formatMessage({id: 'Workflows'})}</h3>
          <button
            type='button'
            className='btn btn-primary'
            onClick={() => {
              navigate({
                pathname: '/workFlow-create',
              })
            }}
          >
            {intl.formatMessage({id: 'Create New WorkFlow'})}
          </button>
        </div>
        {/* end title */}

        {/* start titles */}
        <div className='w-100 m-0 p-0 pe-3'>
          <div className='w-100 d-flex fs-5 fw-bold bg-gray-400 rounded-1 mb-4 p-1 align-items-center'>
            <div className='w-300px p-1 ps-2 h-auto'> {intl.formatMessage({id: 'Name'})} </div>
            <div className='w-75 p-1  ms-3 h-auto d-flex  align-items-center pointer'>
              {' '}
              <span>{intl.formatMessage({id: 'Description'})} </span>{' '}
            </div>

            <div className='w-200px p-1 ms-3 h-auto d-flex justify-content-center align-items-center pointer'>
              <span>{intl.formatMessage({id: 'Created'})}</span>{' '}
            </div>

            <div className='w-100px p-0 ms-2 h-auto d-flex justify-content-center '>  {intl.formatMessage({id: 'Action'})} </div>
          </div>
        </div>
        {/* end titles */}
        {/* start body */}
        <div className={`w-100 h-100 overflow-auto pe-3 m-0`}>
          {workflowData?.map((item, index) => (
            <div
              key={item.id}
              className={`w-100 m-0 p-0 h-auto bg-gray-200 rounded-1 d-flex align-items-center ${
                index === workflowData.length - 1 ? null : 'mb-3'
              } vulnerability_box_item`}
              style={{minHeight: 64}}
            >
              <div className='w-100 d-flex p-1 align-items-center'>
                <div className='w-300px p-1 h-auto d-flex align-items-center'> {item?.name} </div>
                <div className='w-75 p-1 ms-3 h-auto d-flex flex-row align-items-center  text-capitalize'>
                  {item?.description}
                </div>
                <div className='w-200px p-1 ms-3 h-auto d-flex justify-content-center align-items-center'>
                  <ConvertDate date={item.created} />
                </div>
                <div className='w-100px p-1 ms-3 gap-3 h-auto d-flex justify-content-center align-items-center'>
                  <button
                    className={` border border-0 text-white bg-gray-200 p-0 d-flex-justify-content-center visible_icon`}
                    onClick={() => {
                      navigate({
                        pathname: '/workFlow-edit',
                        search: `?id=${item.id}`,
                      })
                    }}
                  >
                    <Edit className='svg-icon svg-icon-2x svg-icon-primary' />
                  </button>
                  <button
                    className={`border border-0 text-white bg-gray-200 p-0  d-flex-justify-content-center visible_icon`}
                    onClick={() => {}}
                  >
                    <Trash className='svg-icon svg-icon-2x svg-icon-danger' />
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
        {/* end body */}
      </div>
    </div>
  ) : null
})

export default WorkflowTable
