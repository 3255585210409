import {FC, Suspense, lazy} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {Dashboard1} from '../pages/dashboard1/Dashboard1'
import {Target} from '../pages/target'
import {Vulnerabilities} from '../pages/vulnerabilities'
import VulnerabilityDetails from '../pages/vulnerabilities/components/VulnerabilityDetails'
import {Asset} from '../pages/asset'
import AssetDetails from '../pages/asset/components/AssetDetails'
import {Waf} from '../pages/waf'
import {DNSInspect} from '../pages/dnsInspect'
import {SMTP} from '../pages/smtp'
import {SSL} from '../pages/ssl'
import {Schedule} from '../pages/schedule'
import {WorkFlow} from '../pages/workFlow'
import DAG from '../pages/workFlow/components/DAG'
import SSLDetails from '../pages/ssl/components/SSLDetails'
import {Error404} from '../modules/errors/components/Error404'
import {Scan} from '../pages/scan'
import ScanDetails from '../pages/scan/components/ScanDetails'
import CreateDAG from '../pages/workFlow/components/CreateDAG'
import Exposures from '../pages/exposures'
import Dos from '../pages/dos'
import Explore from '../pages/exposures/Explore'
import Domains from '../pages/exposures/Domains/Domains'
import Ports from '../pages/exposures/Ports/Ports'
import DNS from '../pages/exposures/DNS/DNS'
import IPs from '../pages/exposures/IPs/IPs'
import Email from '../pages/exposures/Email/Email'
import Leaked from '../pages/exposures/Leaked/Leaked'


const PrivateRoutes = () => {
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        {/* {exposures} */}
        <Route path='/exposures' element={<Exposures />} />
        <Route path='/exposures-explore' element={<Explore />}>
          <Route path='Domains' element={<Domains />} />
          <Route path='Applications' element={<Domains />} />
          <Route path='IP' element={<IPs />} />
          <Route path='DNS Records' element={<DNS />} />
          <Route path='Ports' element={<Ports />} />
          <Route path='SSL' element={<Domains />} />
          <Route path='SMTP' element={<Domains />} />
          <Route path='Email Address' element={<Email />} />
          <Route path='Technologies' element={<Domains />} />
          <Route path='Leaked Data' element={<Leaked />} />
        </Route>
        {/* {exposures} */}

        {/* {workFlow} */}
        <Route path='/workFlow' element={<WorkFlow />} />
        <Route path='/workFlow-edit' element={<DAG />} />
        <Route path='/workFlow-create' element={<CreateDAG />} />
        {/* {workFlow} */}
        {/* {vulnerabilities} */}
        <Route path='/vulnerabilities' element={<Vulnerabilities />} />
        <Route path='/vulnerability-details' element={<VulnerabilityDetails />} />
        {/* {vulnerabilities} */}
        {/* {assets} */}
        <Route path='/assets' element={<Asset />} />
        <Route path='/asset-details' element={<AssetDetails />} />
        {/* {assets} */}
        {/* {scan} */}
        <Route path='/scan' element={<Scan />} />
        <Route path='/scan-details' element={<ScanDetails />} />
        {/* {scan} */}
        {/* {ssl} */}
        <Route path='/ssl' element={<SSL />} />
        <Route path='/ssl-details' element={<SSLDetails />} />
        {/* {ssl} */}
        <Route path='/dashboard' element={<Dashboard1 />} />
        <Route path='/target' element={<Target />} />
        <Route path='/schedule' element={<Schedule />} />
        <Route path='/waf' element={<Waf />} />
        <Route path='/dns' element={<DNSInspect />} />
        <Route path='/smtp' element={<SMTP />} />
        <Route path='/dos' element={<Dos />} />
        <Route
          path='crafted/account/*'
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />
        {/* Page Not Found */}
        <Route path='*' element={<Error404 />} />
        {/* <Route path='*' element={<Navigate to='404' />} /> */}
      </Route>
    </Routes>
  )
}
type SuspensedViewProps = {
  children: React.ReactNode
}
const SuspensedView: FC<SuspensedViewProps> = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
