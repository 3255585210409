/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {KTSVG} from '../../../../_metronic/helpers'
import PageLoading from '../../loading/PageLoading'
import axios from 'axios'
import useErrorHandling from '../../../../Utils/useErrorHandling'
import {VUL_DETAILS_DATA} from '../../../modules/auth/redux/DEMO_DATA'
import ConvertDate from '../../../../Utils/ConvertDate'
import {useIntl} from 'react-intl'
import {useLang} from '../../../../_metronic/i18n/Metronici18n'
import EmptyPage from '../../emptyPage/EmptyPage'

interface Props {
  setId: React.Dispatch<React.SetStateAction<number | null>>
  id: number | null
}
interface Alert {
  status: boolean
  massage: string
}
export const DetailsDrawer = ({id, setId}: Props) => {
  const [data, setData] = useState<any>(null)
  const [loading, setLoading] = useState(true)
  const [commentLoading, setCommentLoading] = useState(false)
  const [commentValue, setCommentValue] = useState('')
  const [commentMassage, setCommentMassage] = useState<Alert | null>(null)
  const errorHandling = useErrorHandling()
  const lang = useLang()?.lang.selectedLang

  const intl = useIntl()
  const severityContent = (severity: string) => {
    switch (severity) {
      case 'info':
        return (
          <>
            <div className={`w-15px h-15px rounded-3 border border-2 border-iinfo me-3`} />
            <span className='m-auto'>{intl.formatMessage({id: 'Info Severity'})} </span>
          </>
        )
      case 'low':
        return (
          <>
            <div className={`w-15px h-15px rounded-3 border border-2 border-ilow me-3`} />
            <span className='m-auto'> {intl.formatMessage({id: 'Low Severity'})} </span>
          </>
        )

      case 'medium':
        return (
          <>
            <div className={`w-15px h-15px rounded-3 border border-2 border-imedium me-1`} />
            <span className='m-auto'>{intl.formatMessage({id: 'Medium Severity'})} </span>
          </>
        )

      case 'high':
        return (
          <>
            <div className={`w-15px h-15px rounded-3 border border-2 border-ihigh me-3`} />
            <span className='m-auto'>{intl.formatMessage({id: 'High Severity'})} </span>
          </>
        )

      case 'critical':
        return (
          <>
            <div className={`w-15px h-15px rounded-3 border border-2 border-icritical me-3`} />
            <span className='m-auto'>{intl.formatMessage({id: 'Critical Severity'})} </span>
          </>
        )

      default:
        return (
          <>
            <div className={`w-15px h-15px rounded-3 border border-2 border-white me-3`} />
            <span className='m-auto'>{intl.formatMessage({id: 'None'})}</span>
          </>
        )
    }
  }
  const fetchVulnerabilityDetailData = async () => {
    setLoading(true)
    try {
      const data = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/vulnerabilities/${id}/`)
      errorHandling(data.status) //logout when invalid token && redirect 404
      if (data.status >= 200 && data.status < 300) {
        setData(data.data)
      } else {
        console.error(
          `error in get data from ${process.env.REACT_APP_API_ENDPOINT}/vulnerabilities/${id} \n`,
          data
        )
      }
      setLoading(false)
    } catch (e) {
      console.log(e)
      setLoading(false)
    }
  }
  useEffect(() => {
    if (id !== null) {
      if (process.env.REACT_APP_MODE === 'demo') {
        setData(VUL_DETAILS_DATA)
      } else {
        fetchVulnerabilityDetailData()
      }
    }
  }, [id])
  function sendComment () {
    setCommentLoading(true)
    axios
      .post(`${process.env.REACT_APP_API_ENDPOINT}/vulnerabilities/${id}/comment/`, {
        comment: commentValue,
      })
      .then((res) => {
        setCommentValue('')
        setCommentLoading(false)
        setCommentMassage({
          status: true,
          massage: intl.formatMessage({id: 'Your comment has been sent'}),
        })
        fetchVulnerabilityDetailData()
      })
      .catch((e) => {
        console.error(e)
        console.log('message \n', e?.response?.data?.error)
        setCommentLoading(false)
        setCommentMassage({
          status: false,
          massage: intl.formatMessage({id: 'Your comment was not sent'}),
        })
      })
      .finally(() => {
        setTimeout(() => {
          setCommentMassage(null)
        }, 10000)
      })
  }
  return (
    <>
      {/* begin::Demos drawer */}
      <div
        id='kt_engage_filters'
        className={`bg-body drawer drawer-end ${id !== null ? 'drawer-on' : ''}`}
        data-kt-drawer-width="{default:'350px', 'lg': '375px'}"
        style={{minWidth: 400, maxWidth: 400}}
      >
        {data ? (
          <>
            {/* begin::Card  */}
            <div className='card shadow-none w-100' style={{height: 'fit-content'}}>
              {/* begin::Header */}
              <div className='card-header' id='kt_explore_header'>
                <h5 className='card-title fw-bold text-gray-600'>{!loading && data?.cve}</h5>

                <div className='card-toolbar'>
                  <button
                    type='button'
                    className='btn btn-sm btn-icon explore-btn-dismiss me-n5'
                    id='kt_engage_filters_close'
                    onClick={() => {
                      setId(null)
                    }}
                  >
                    <KTSVG
                      path='/media/icons/duotune/arrows/arr061.svg'
                      className='svg-icon-2 image_change_theme2'
                    />
                  </button>
                </div>
              </div>
              {/* end::Header */}
              {loading ? (
                <PageLoading />
              ) : (
                <>
                  {/* begin::Body */}
                  <div className='card-body d-flex flex-column gap-7' id='kt_explore_body'>
                    {/* begin::Content */}

                    <div className='d-flex flex-column justify-content-start'>
                      <div className='p-1 text-gray-700 h-auto'>
                        {intl.formatMessage({id: 'AFFECTED ENTITY'})}{' '}
                      </div>
                      <div className='w-200px p-1 mt-2 d-flex align-items-center text-primary h-auto'>
                        {data?.host}
                      </div>
                    </div>
                    <div className='d-flex flex-column justify-content-start'>
                      <div className='w-200px p-1 text-gray-700 h-auto'>
                        {intl.formatMessage({id: 'Targets'})}{' '}
                      </div>
                      <div className='w-200px p-1 mt-1 d-flex align-items-center text-gray-800 h-auto'>
                        <div
                          className={`w-30px h-30px bg-gray-500 text-white rounded-1 d-flex justify-content-center align-items-center p-1 me-3`}
                        >
                          {data?.tag}
                        </div>
                        {data?.target}
                      </div>
                    </div>

                    <div>
                      <span className='min-w-200px p-1 text-gray-700 h-auto'>
                        {intl.formatMessage({id: 'Risk'})}
                      </span>
                      <div className='px-3 py-1 d-flex mw-200px my-2 align-items-center bg-secondary rounded-3'>
                        {severityContent(data?.severity)}
                      </div>
                    </div>

                    <div className='d-flex flex-column justify-content-start'>
                      <div className='min-w-200px p-1 text-gray-700 h-auto'>
                        {intl.formatMessage({id: 'AFFECTED TECHNOLOGIES'})}
                      </div>
                      <div className='p-1 mt-2 text-gray-900'>{data?.component}</div>
                    </div>
                    <div className='d-flex flex-column justify-content-start'>
                      <div className='w-150px p-1 text-gray-700 h-auto'>
                        {' '}
                        {intl.formatMessage({id: 'Last Seen'})}{' '}
                      </div>
                      <span className='w-150px p-1 mt-2 text-gray-900 h-auto'>
                        <ConvertDate date={data?.timestamp} />
                      </span>
                    </div>
                    <div>
                      <div className='min-w-200px p-1 text-gray-700 h-auto'>
                        {intl.formatMessage({id: 'Description'})}
                      </div>
                      <div className='my-2'>
                        {lang === 'fa'
                          ? data?.description_fa || 'منتظر داده باشید'
                          : data?.description}
                      </div>
                    </div>
                    <div className=' d-flex flex-column '>
                      <label className='form-label'>{intl.formatMessage({id: 'Comment'})}</label>
                      <div className='my-2'>{data?.comment}</div>
                      <textarea
                        onChange={(e) => {
                          setCommentValue(e.target.value)
                        }}
                        value={commentValue}
                        rows={1}
                        className='form-control form-control-solid'
                        data-kt-autosize='true'
                        placeholder={intl.formatMessage({id: 'Comment'})}
                      ></textarea>
                    </div>
                    <div>
                      {!!commentMassage && (
                        <p style={commentMassage.status ? {color: 'green'} : {color: 'red'}}>
                          {commentMassage.massage}
                        </p>
                      )}
                      {process.env.REACT_APP_MODE === 'demo' && (
                        <p className='text-danger'>
                          {intl.formatMessage({id: 'This feature is not available in demo mode'})}
                        </p>
                      )}

                      <button
                        className='btn btn-primary btn-sm w-100'
                        disabled={!commentValue || process.env.REACT_APP_MODE === 'demo'}
                        onClick={sendComment}
                      >
                        {commentLoading
                          ? `${intl.formatMessage({id: 'Sending'})}...`
                          : intl.formatMessage({id: 'Send Comment'})}
                      </button>
                    </div>
                    {/* end::Content */}
                  </div>
                  {/* end::Body */}
                </>
              )}
            </div>
            {/* begin::Card */}
          </>
        ) : (
          <EmptyPage />
        )}
      </div>
      {/* end::Demos drawer */}
    </>
  )
}
