export const  EmailsData =[
    {
        email:'test@example.com',
        username:'test',
    },
    {
        email:'test@example.com',
        username:'test',
    },
    {
        email:'test@example.com',
        username:'test',
    },
    {
        email:'test@example.com',
        username:'test',
    },
    {
        email:'test@example.com',
        username:'test',
    },
    {
        email:'test@example.com',
        username:'test',
    },
    {
        email:'test@example.com',
        username:'test',
    },
    {
        email:'test@example.com',
        username:'test',
    },
    {
        email:'test@example.com',
        username:'test',
    },
    {
        email:'test@example.com',
        username:'test',
    },
]