import { createSlice } from '@reduxjs/toolkit'
import { demoData } from './DEMO_DATA'
import { UserModel } from '../models/UserModel'
import axios from 'axios'
interface DATA {
  data: undefined | any,
  expireTime: null | number
}
export interface IAuthState {
  data: {
    dataDashboard: DATA
    dataScope: DATA
    dataAsset: DATA
    dataVulnerability: DATA
    dataSSL: DATA
    dataDns: DATA
    dataSmtp: DATA
    dataScanDAG: DATA
    dataScan: DATA
  }
  filterOption: {
    assets: {
      options: any,
      selected: any
    },
    vulnerabilities: {
      options: any,
      selected: any,
      search: string
    }
  }
  user: UserModel | undefined | null
  accessToken: string | undefined | null
}
const initialAuthState: IAuthState = {
  data: {
    dataDashboard: {
      data: undefined,
      expireTime: null
    },
    dataScope: {
      data: undefined,
      expireTime: null
    },
    dataAsset: {
      data: undefined,
      expireTime: null
    },
    dataVulnerability: {
      data: undefined,
      expireTime: null
    },
    dataDns: {
      data: undefined,
      expireTime: null
    },
    dataSSL: {
      data: undefined,
      expireTime: null
    },
    dataSmtp: {
      data: undefined,
      expireTime: null
    },
    dataScanDAG: {
      data: undefined,
      expireTime: null
    },
    dataScan: {
      data: undefined,
      expireTime: null
    },
  },
  filterOption: {
    assets: {
      options: null,
      selected: {}
    },
    vulnerabilities: {
      options: null,
      selected: {},
      search: ''
    }
  },
  user: null,
  accessToken: null,
}


function generateExpireTime(time: number = 5) {
  const expireTime = new Date().getTime() + (time * 60 * 1000)
  return expireTime
}
const rootSlice = createSlice({
  name: 'Root',
  initialState: process.env.REACT_APP_MODE === 'demo' ? demoData : initialAuthState,
  reducers: {
    FilterSelectedAssets(state, action) {
      state.filterOption.assets.selected = action.payload
    },
    FilterOptionAssets(state, action) {
      state.filterOption.assets.options = action.payload
    },
    FilterSelectedVulnerabilities(state, action) {
      state.filterOption.vulnerabilities.selected = action.payload
    },
    FilterOptionVulnerabilities(state, action) {
      state.filterOption.vulnerabilities.options = action.payload
    },
    SearchVulnerabilities(state, action) {
      state.filterOption.vulnerabilities.search = action.payload
    },
    Logout_(state, action) {
      if (!(process.env.REACT_APP_MODE === 'demo')) {
        if (action.payload) {
          axios.post(`${process.env.REACT_APP_API_ENDPOINT}/auth/jwt/logout/`).then((res) => {
          }).catch((e) => {
          })
          return initialAuthState
        } else {
          return initialAuthState
        }
      } else {
        return demoData
      }
    },
    UserLoaded(state, action) {
      const user = action.payload
      state.user = user
    },
    SetUser(state, action) {
      const user = action.payload
      state.user = user
    },
    SetDataDashboard(state, action) {
      const payloadData = action.payload
      const newData: DATA = {
        data: payloadData,
        expireTime: generateExpireTime()
      }
      state.data.dataDashboard = newData
    },
    SetDataScope(state, action) {
      const data = action.payload
      const newData: DATA = {
        data: data,
        expireTime: generateExpireTime()
      }
      state.data.dataScope = newData
    },
    SetDataAsset(state, action) {
      const data = action.payload
      const newData: DATA = {
        data: data,
        expireTime: generateExpireTime()
      }
      state.data.dataAsset = newData
    },
    SetDataVulnerability(state, action) {
      const data = action.payload
      const newData: DATA = {
        data: data,
        expireTime: generateExpireTime()
      }
      state.data.dataVulnerability = newData
    },
    SetDataSSL(state, action) {
      const data = action.payload
      const newData: DATA = {
        data: data,
        expireTime: generateExpireTime()
      }
      state.data.dataSSL = newData
    },
    SetDataDNS(state, action) {
      const data = action.payload
      const newData: DATA = {
        data: data,
        expireTime: generateExpireTime()
      }
      state.data.dataDns = newData
    },
    SetDataSmtp(state, action) {
      const data = action.payload
      const newData: DATA = {
        data: data,
        expireTime: generateExpireTime()
      }
      state.data.dataSmtp = newData
    },
    SetDataScan(state, action) {
      const data = action.payload
      const newData: DATA = {
        data: data,
        expireTime: generateExpireTime()
      }
      state.data.dataScan = newData
    },
    SetDataScanDAG(state, action) {
      const data = action.payload
      const newData: DATA = {
        data: data,
        expireTime: generateExpireTime()
      }
      state.data.dataScanDAG = newData
    },
  },
})

export const {
  Logout_,
  UserLoaded,
  SetUser,
  SetDataDashboard,
  SetDataScope,
  SetDataAsset,
  SetDataVulnerability,
  SetDataSSL,
  SetDataDNS,
  SetDataSmtp,
  SetDataScan,
  SetDataScanDAG,
  FilterOptionAssets,
  FilterSelectedAssets,
  FilterSelectedVulnerabilities,
  FilterOptionVulnerabilities,
  SearchVulnerabilities
} = rootSlice.actions
export default rootSlice.reducer


//////////////////////////////////////////////////////////////////////////////////////////////////////////////////

// import { Action } from '@reduxjs/toolkit'
// import { persistReducer } from 'redux-persist'
// import storage from 'redux-persist/lib/storage'
// import {getUserByToken} from './AuthCRUD'
// import {select} from 'redux-saga/effects'

// export interface ActionWithPayload<T> extends Action {
//   payload?: T
// }

// export const actionTypes = {
//   Login: '[Login] Action',
//   Logout: '[Logout] Action',
//   Register: '[Register] Action',
//   UserRequested: '[Request User] Action',
//   UserLoaded: '[Load User] Auth API',
//   SetUser: '[Set User] Action',
//   SetDataDashboard: '[DATA] DASHBOARD',
//   SetDataScope: '[DATA] SCOPE',
//   SetDataAsset: '[DATA] ASSET',
//   SetDataVulnerability: '[DATA] VULNERABILITY',
//   SetDataSSL: '[DATA] SSL',
//   SetDataDNS: '[DATA] DNS',
//   SetDataSmtp: '[DATA] SMTP',
//   SetDataScanDAG: '[DATA] SCAN DAG',
//   SetDataScan: '[DATA] SCAN',

// }


// export const reducer = persistReducer(
//   { storage, key: 'v100-demo1-auth', whitelist: ['user', 'accessToken'] },
//   (state: IAuthState = process.env.REACT_APP_MODE === 'demo' ? demoData : initialAuthState, action: ActionWithPayload<IAuthState>) => {
//     switch (action.type) {
//       case actionTypes.Login: {
//         const accessToken = action.payload?.accessToken
//         console.log('state', state)
//         return { ...state, accessToken, user: undefined }
//       }

//       case actionTypes.Register: {
//         const accessToken = action.payload?.accessToken
//         return { ...state, accessToken, user: undefined }
//       }

//       case actionTypes.Logout: {
//         if(!(process.env.REACT_APP_MODE === 'demo')){
//           axios.post(`${process.env.REACT_APP_API_ENDPOINT}/auth/jwt/logout/`).then((res) => {
//           }).catch((e) => {
//           })
//         }
//         return process.env.REACT_APP_MODE === 'demo' ? demoData : initialAuthState
//       }

//       case actionTypes.UserLoaded: {
//         const user = action.payload?.user
//         return { ...state, user }
//       }

//       case actionTypes.SetUser: {
//         const user = action.payload?.user
//         return { ...state, user }
//       }

//       case actionTypes.SetDataDashboard: {
//         const payloadData = action.payload?.data
//         const newData: DATA = {
//           data: payloadData,
//           expireTime: generateExpireTime()
//         }
//         const copy = structuredClone(state)
//         copy.data.dataDashboard = newData
//         return copy
//       }

//       case actionTypes.SetDataScope: {
//         const data = action.payload?.data
//         const newData: DATA = {
//           data: data,
//           expireTime: generateExpireTime()
//         }
//         const copy = structuredClone(state)
//         copy.data.dataScope = newData
//         return copy
//       }

//       case actionTypes.SetDataAsset: {
//         const data = action.payload?.data
//         const newData: DATA = {
//           data: data,
//           expireTime: generateExpireTime()
//         }
//         const copy = structuredClone(state)
//         copy.data.dataAsset = newData
//         return copy
//       }

//       case actionTypes.SetDataVulnerability: {
//         const data = action.payload?.data
//         const newData: DATA = {
//           data: data,
//           expireTime: generateExpireTime()
//         }
//         const copy = structuredClone(state)
//         copy.data.dataVulnerability = newData
//         return copy
//       }

//       case actionTypes.SetDataSSL: {
//         const data = action.payload?.data
//         const newData: DATA = {
//           data: data,
//           expireTime: generateExpireTime()
//         }
//         const copy = structuredClone(state)
//         copy.data.dataSSL = newData
//         return copy
//       }

//       case actionTypes.SetDataDNS: {
//         const data = action.payload?.data
//         const newData: DATA = {
//           data: data,
//           expireTime: generateExpireTime()
//         }
//         const copy = structuredClone(state)
//         copy.data.dataDns = newData
//         return copy
//       }

//       case actionTypes.SetDataSmtp: {
//         const data = action.payload?.data
//         const newData: DATA = {
//           data: data,
//           expireTime: generateExpireTime()
//         }
//         const copy = structuredClone(state)
//         copy.data.dataSmtp = newData
//         return copy
//       }

//       case actionTypes.SetDataScan: {
//         const data = action.payload?.data
//         const newData: DATA = {
//           data: data,
//           expireTime: generateExpireTime()
//         }
//         const copy = structuredClone(state)
//         copy.data.dataScan = newData
//         return copy
//       }

//       case actionTypes.SetDataScanDAG: {
//         const data = action.payload?.data
//         const newData: DATA = {
//           data: data,
//           expireTime: generateExpireTime()
//         }
//         const copy = structuredClone(state)
//         copy.data.dataScanDAG = newData
//         return copy
//       }

//       default:
//         return state
//     }
//   }
// )

// export const actions = {
//   login: (accessToken: string) => ({ type: actionTypes.Login, payload: { accessToken } }),
//   register: (accessToken: string) => ({
//     type: actionTypes.Register,
//     payload: { accessToken },
//   }),
//   logout: () => ({ type: actionTypes.Logout }),
//   requestUser: () => ({
//     type: actionTypes.UserRequested,
//   }),
//   fulfillUser: (user: UserModel) => ({ type: actionTypes.UserLoaded, payload: { user } }),
//   setUser: (user: UserModel) => ({ type: actionTypes.SetUser, payload: { user } }),
//   setDataDashboard: (data: any) => ({ type: actionTypes.SetDataDashboard, payload: { data } }),
//   setDataScope: (data: any) => ({ type: actionTypes.SetDataScope, payload: { data } }),
//   setDataAsset: (data: any) => ({ type: actionTypes.SetDataAsset, payload: { data } }),
//   setDataVulnerability: (data: any) => ({ type: actionTypes.SetDataVulnerability, payload: { data } }),
//   setDataSSL: (data: any) => ({ type: actionTypes.SetDataSSL, payload: { data } }),
//   setDataDns: (data: any) => ({ type: actionTypes.SetDataDNS, payload: { data } }),
//   setDataSmtp: (data: any) => ({ type: actionTypes.SetDataSmtp, payload: { data } }),
//   setDataScan: (data: any) => ({ type: actionTypes.SetDataScan, payload: { data } }),
//   setDataScanDAG: (data: any) => ({ type: actionTypes.SetDataScanDAG, payload: { data } }),
// }