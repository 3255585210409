import * as React from "react"
const Arr001 = ({
  className='',
  fill = '#f00'
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    className={className}
  >
    <path fill={fill} d="M14.4 11H3c-.6 0-1 .4-1 1s.4 1 1 1h11.4v-2Z" />
    <path
      fill={fill}
      d="M14.4 20V4l7.3 7.3c.4.4.4 1 0 1.4L14.4 20Z"
      opacity={0.3}
    />
  </svg>
)
export default Arr001
