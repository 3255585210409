import React, {memo} from 'react'
import InfoIcon from '../../images/InfoIcon'
import {Tooltip} from 'react-tooltip'
import { useIntl } from 'react-intl'
interface DATA {
  count: number
  desc: string
  header: string
  title: string
}
const Assets = memo(
  ({
    className,
    data,
    tooltip = 'Tooltip...',
  }: {
    className?: string
    data: DATA
    tooltip?: string
  }) => {
    const intl = useIntl()
    return (
      // <div className='w-100 h100'>
      <div className={`bg-body h-100 card mh-250px`}>
        {/* start title */}
        <div
          className={`w-100 h-50px p-4 fw-bolder d-flex justify-content-center align-items-center text-body bg-secondary rounded-top`}
        >
          {intl.formatMessage({id: (data.header||'Address')})}
          <span data-tooltip-id={'my-tooltip'+data.title} data-tooltip-content={intl.formatMessage({id: tooltip})}>
            <InfoIcon />
          </span>
          <Tooltip id={'my-tooltip'+data.title} style={{maxWidth:250,fontSize:12 ,textAlign:'center'}}/>
        </div>
        <div
          className={`w-100 h-100 p-0 pt-4 d-flex flex-column justify-content-center align-items-center ${className}`}
        >
          <span
            style={{
              fontSize: '3em',
            }}
            className='pt-2 d-flex align-items-center fw-bolder text-igray'
          >
            {data?.count}
          </span>
          <span className='d-flex align-items-center fw-bolder text-gray-700'>{intl.formatMessage({id: (data.title||'Address')})}</span>
          <span className='d-flex w-100 align-items-center fs-5 justify-content-center fw-bolder text-gray-700 mt-2'>
            {intl.formatMessage({id: (data.desc||'Address')})}
          </span>
        </div>
      </div>
      // </div>
    )
  }
)

export default Assets
