import React, {useState, useEffect} from 'react'
import axios from 'axios'
import ScanTable from './components/ScanTable'
import PageLoading from '../loading/PageLoading'
// import ReactPaginate from 'react-paginate'
import useErrorHandling from '../../../Utils/useErrorHandling'
import {useDispatch, useSelector} from 'react-redux'
import {RootState} from '../../../setup/redux/Store'
import {SetDataScan, SetDataScanDAG} from '../../modules/auth'
import ReactPaginate from 'react-paginate'
import EmptyPage from '../emptyPage/EmptyPage'
import {ColumnChart} from './components/ColumnChart'
import {LineChart} from './components/LineChart'
import DAG from './components/DAG'
import Arr001 from '../components/Arr001'
const chartData = {
  x: ['2024/10/10', '2024/10/11', '2024/10/12', '2024/10/13'],
  y: [
    77, 155, 87, 86, 1, 188, 63, 160, 66, 77, 188, 87, 86, 11, 88, 63, 160, 66, 77, 188, 87, 86, 1,
    188, 63, 160, 66, 77, 188, 87, 86, 11, 88, 63, 160, 66,
  ],
}
const leftChart = {
  x: ['2024/10/10', '2024/10/11', '2024/10/12', '2024/10/13', '2024/10/14'],
  y: [
    {
      name: 'string',
      data: [11, 15, 18, 22, 33],
    },
    {
      name: 'string2',
      data: [3, 8, 11, 18, 25],
    },
  ],
}
const rightChart = {
  x: ['2024/10/10', '2024/10/11', '2024/10/12', '2024/10/13', '2024/10/14'],
  y: [
    {
      name: 'string',
      data: [11, 15, 18, 22, 33],
    },
  ],
}
export const Scan = () => {
  const [loading, setLoading] = useState(false)
  const [flipCard, setFlipCard] = useState<null|number>(null)
  const scanDagData = useSelector((state: RootState) => state?.root?.data?.dataScanDAG)
  const scanData = useSelector((state: RootState) => state?.root?.data?.dataScan)
  const dispatch = useDispatch()
  const errorHandling = useErrorHandling()
  // const handlePageClick = (event: {selected: number}) => {
  //   fetchScanData(event.selected + 1)
  // }
  const fetchDagsData = async () => {
    setLoading(true)
    try {
      const data = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/workflow/`)
      errorHandling(data.status)
      if (data.status >= 200 && data.status < 300) {
        dispatch(SetDataScanDAG(data.data))
      } else {
        console.error(
          `error in get data from ${process.env.REACT_APP_API_ENDPOINT}/workflow/ \n`,
          data
        )
      }
      setLoading(false)
    } catch (e) {
      setLoading(false)
    }
  }

  const fetchScanData = async (page = 1) => {
    setLoading(true)
    try {
      const data = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/scan/`)
      errorHandling(data.status)
      if (data.status >= 200 && data.status < 300) {
        dispatch(SetDataScan(data.data))
      } else {
        console.error(
          `error in get data from ${process.env.REACT_APP_API_ENDPOINT}/scan/?page=${page} \n`,
          data
        )
      }
      setLoading(false)
    } catch (e) {
      setLoading(false)
    }
  }

  useEffect(() => {
    const nowDate = new Date().getTime()
    if (scanData?.expireTime && scanData.expireTime >= nowDate && scanData?.data) {
    } else {
      fetchScanData()
    }
    if (scanDagData?.expireTime && scanDagData.expireTime >= nowDate && scanDagData?.data) {
    } else {
      fetchDagsData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      {loading ? (
        <PageLoading />
      ) : scanData?.data && scanData?.data?.length ? (
        <div className='w-100 d-flex gap-5'>
          <div className='w-50 card' style={{minWidth: 540}}>
            <div className='h-300px p-3'>
              <ColumnChart chartData={chartData} />
            </div>
            <ScanTable
              flipCard={flipCard}
              setFlipCard={setFlipCard}
              scanData={scanData?.data}
              dagsData={scanDagData?.data}
              getData={fetchScanData}
            />
            <div className='d-flex justify-content-end bg-body pb-4 pe-4 rounded-bottom'>
              {/* <ReactPaginate
              nextLabel={`${intl.formatMessage({id: 'Next'})} >`}
              onPageChange={handlePageClick}
              pageRangeDisplayed={3}
              marginPagesDisplayed={2}
              pageCount={scanData?.total_pages}
              forcePage={scanData?.current_page - 1}
              previousLabel={`< ${intl.formatMessage({id: 'Previous'})}`}
              pageClassName='page-item'
              pageLinkClassName='page-link'
              previousClassName='page-item'
              previousLinkClassName='page-link'
              nextClassName='page-item'
              nextLinkClassName='page-link'
              breakLabel='...'
              breakClassName='page-item'
              breakLinkClassName='page-link'
              containerClassName='pagination'
              activeClassName='active'
              renderOnZeroPageCount={null}
            /> */}
            </div>
          </div>
          <div className='w-50' style={{minWidth: 540}}>
            <div
              className='w-100 position-relative h-200px inner_box'
              style={flipCard !==null ? {transform: 'rotateX(180deg)'} : {}}
            >
              <div className='w-100  d-flex gap-5 front_card'>
                <div className='w-50 card p-3 py-5' style={{height: 'fit-content'}}>
                  <h2>Task Runs</h2>
                  <div className='d-flex gap-5 h-100px mt-2'>
                    <p style={{fontSize: 65, height: 'fit-content'}}>1.5K</p>
                    <LineChart chartData={leftChart} />
                  </div>
                </div>
                <div className='w-50 card p-3 py-5' style={{height: 'fit-content'}}>
                  <h2>Events</h2>
                  <div className='d-flex gap-5 h-100px mt-2'>
                    <p style={{fontSize: 65, height: 'fit-content'}}>7.5K</p>
                    <LineChart chartData={rightChart} />
                  </div>
                </div>
              </div>
              <div className='w-100 h-200px card  back_card position-relative'>
                <button
                  className={`col-auto border border-0   p-0 m-0 position-absolute `}
                  style={{rotate: '180deg', background: 'unset', top: 10, left: 10, zIndex: 100}}
                  onClick={() => {
                    setFlipCard(null)
                  }}
                >
                  <Arr001 fill={'#fff'} className={'image_change_theme'} />
                </button>
                <DAG />
              </div>
            </div>
            <div className='w-100 h-50 card mt-5'></div>
          </div>
        </div>
      ) : (
        <EmptyPage />
      )}
    </>
  )
}
