
import Card from './Card/Card'
import CardProvider from './Card/CardProvider'
import Chart from './Card/Chart'
import Footer from './Card/Footer'
import Header from './Card/Header'
import {CardData} from './Data'
import {Data} from './Data/Data'

export default function Exposures () {
  const {DNS, port, ssl, smtp, mail, technologies , leaked} = CardData
  return (
    <div className='d-flex w-100 mt-5 flex-wrap gap-10 mb-10 justify-content-between'>
      <div className='d-flex flex-column gap-10 w-30%' style={{width: '30%', minWidth: 370}}>
        {Data.metrics?.map((item, index) => (
          <Card cardData={item} key={index} />
        ))}
      </div>
      <div className='d-flex flex-column gap-10 w-30%' style={{width: '30%', minWidth: 370}}>

        <CardProvider link={port.link}>
          <Header icon={port.icon} name={port.title} count={port.count} />
          <Chart list={port.chart} />
          <Footer list={port.footer} />
        </CardProvider>
        <CardProvider link={DNS.link}>
          <Header icon={DNS.icon} name={DNS.title} count={DNS.count} />
          <Footer list={DNS.footer} />
        </CardProvider>
        <CardProvider link={ssl.link}>
          <Header icon={ssl.icon} name={ssl.title} count={ssl.count} />
        </CardProvider>
      </div>
      <div className='d-flex flex-column gap-10 w-30%' style={{width: '30%', minWidth: 370}}>

        <CardProvider link={smtp.link}>
          <Header icon={smtp.icon} name={smtp.title} count={smtp.count} />
        </CardProvider>
        <CardProvider link={mail.link}>
          <Header icon={mail.icon} name={mail.title} count={mail.count} />
        </CardProvider>
        <CardProvider link={technologies.link}>
          <Header icon={technologies.icon} name={technologies.title} count={technologies.count} />
        </CardProvider>
        <CardProvider link={leaked.link}>
          <Header icon={leaked.icon} name={leaked.title} count={leaked.count} />
        </CardProvider>
      </div>
    </div>
  )
}
