import {useIntl} from 'react-intl'
import {MenuItem} from './MenuItem'
// import {MenuInnerWithSub} from './MenuInnerWithSub'
// import {MegaMenu} from './MegaMenu'

export function MenuInner() {
  const intl = useIntl()
  return (
    <>
      <MenuItem title={intl.formatMessage({id: 'Dashboard'})} to='/dashboard' />
      <MenuItem title={intl.formatMessage({id: 'Targets' })} to='/target' />
      <MenuItem title={intl.formatMessage({id: 'Assets' })} to='/assets' />
      <MenuItem title={intl.formatMessage({id: 'Vulnerabilities' })} to='/vulnerabilities' />
      
    </>
  )
}
