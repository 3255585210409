import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import image0 from '../../images/0.webp'
import list from '../../images/List.webp'
import px500 from '../../images/500px.webp'

export default function Leaked () {
  const intl = useIntl()

  return (
    <>
      <div className='w-100  p-10 bg-body ' style={{background: 'linear-gradient(135deg, #320f0f 0, #ba4343 100%)'}}>
        <div className='w-100  d-flex pt-5 gap-10'>
          <div className='w-115px h-100px'>
            <img src={image0} width={115} height={100} />
          </div>
          <div>
            <p className='text-white fs-4'>
             <strong>000webhost:</strong>  In approximately March 2015, the free web hosting provider <span className='custom_text_decoration'>000webhost
              suffered a major data breach</span> that exposed almost 15 million customer records. The data
              was sold and traded before 000webhost was alerted in October. The breach included
              names, email addresses and plain text passwords.
            </p>
            <p className='text-white fs-4'><strong> Compromised data:</strong> Email addresses, IP addresses, Names, Passwords</p>
          </div>
        </div>
        <div className='w-100  d-flex pt-10 gap-10'>
          <div className='w-115px h-100px'>
            <img src={list} width={115} height={100} />
          </div>

          <div>
            <p className='text-white fs-4'>
            <strong>2,844 Separate Data Breaches <span className='custom_text_decoration' style={{color:'#a65c5c'}}>(unverified)</span>:</strong> In February 2018, <span className='custom_text_decoration'>a massive collection of
              almost 3,000 alleged data breaches was found online.</span> Whilst some of the data had
              previously been seen in Have I Been Pwned, 2,844 of the files consisting of more than
              80 million unique email addresses had not previously been seen. Each file contained
              both an email address and plain text password and were consequently loaded as a single
              "unverified" data breach.
            </p>
            <p className='text-white fs-4'><strong>Compromised data:</strong> Email addresses, Passwords</p>
          </div>
        </div>
        <div className='w-100  d-flex pt-10 gap-10'>
          <div className='w-115px h-100px'>
            <img src={px500} width={115} height={30} />
          </div>
          <div>
            <p className='text-white fs-4'>
            <strong>500px:</strong> In mid-2018, the online photography community <span className='custom_text_decoration'>500px suffered a data breach.</span> The
              incident exposed almost 15 million unique email addresses alongside names, usernames,
              genders, dates of birth and either an MD5 or bcrypt password hash. In 2019, <span className='custom_text_decoration'>the data
              appeared listed for sale on a dark web marketplace</span> (along with several other large
              breaches) and subsequently began circulating more broadly. The data was provided to
              HIBP by a source who requested it to be attributed to "BenjaminBlue@exploit.im".
            </p>
            <p className='text-white fs-4'>
            <strong>Compromised data:</strong> Dates of birth, Email addresses, Genders, Geographic locations,
              Names, Passwords, Usernames
            </p>
          </div>
        </div>
      </div>
    </>
  )
}
