
import { useIntl } from 'react-intl'
import sample from '../../sample'





const WafPage = () => {
  const intl = useIntl()
  return (
    <div className='w-100 h-100'>
      <div className={`card card-body h-100 bg-body`}>
        {/* start title */}
        <div className='w-100 pe-3 d-flex h-100px flex-row justify-content-between align-items-start pt-2'>
          <h3 className=' '>{sample.waf.header_title}</h3>
        </div>
        {/* start body */}
        {/* start titles */}
        <div className='w-100 m-0 pe-3 '>
          <div className='row w-100 h-150px d-flex border border-2 rounded-3 m-auto mb-4 p-0'>
            <div className='col-3 p-3 d-flex flex-column rounded-start justify-content-between bg-wafRed'>
              <div className='fs-6 fw-bold'>{intl.formatMessage({id: 'Overall grade'})}</div>
              <div
                className='d-flex ps-8 fw-bolder text-capitalize'
                style={{
                  lineHeight: 1,
                  fontSize: '6rem',
                }}
              >
                {sample.waf.grade}
              </div>
              <div className='d-flex justify-content-end pe-5 fs-7 fs-bold'> {sample.waf.ip}</div>
            </div>
            <div className='col-9 p-3 d-flex flex-column'>
              <div className='fs-8 d-flex flex-row'>
                <span className='fs-8 fw-bold bg-gray-300 mx-3'>{intl.formatMessage({id: 'WAF'})}:</span>
                {sample.waf.name}
              </div>
              <div className='fs-8 d-flex flex-row mt-2'>
                <span className='fs-8 fw-bold bg-gray-300 mx-3'>{intl.formatMessage({id: 'URL'})}:</span>
                {sample.waf.url}
              </div>
              <div className='fs-8 d-flex flex-row mt-2'>
                <span className='fs-8 fw-bold bg-gray-300 mx-3'>{intl.formatMessage({id: 'Testing Date'})}</span>
                {sample.waf.testDate}
              </div>
            </div>
          </div>
        </div>

        <div className='w-100 m-0 pe-3 '>
          <div className='row w-100 d-flex m-auto mb-4 p-0'>
            <div className='col-3 p-3 px-0 pe-2 d-flex flex-column justify-content-start'>
              <div className='bg-gray-400 p-2 py-0 h-40px d-flex align-items-center rounded-1'>
                {intl.formatMessage({id: 'Type'})}
              </div>
              <div className='bg-gray-400 mt-3 p-2 py-0 h-40px d-flex align-items-center rounded-1'>
                {intl.formatMessage({id: 'Application Security'})}
              </div>
            </div>
            <div className='col-3 p-3 px-0 pe-2 d-flex flex-column justify-content-start'>
              <div className='bg-gray-400 p-2 py-0 h-40px d-flex align-items-center rounded-1'>
                {intl.formatMessage({id: 'True-negative tests blocked'})}
              </div>

              <div className='bg-wafRedLight mt-3 fw-bolder p-2 pe-5 py-0 h-40px d-flex justify-content-between align-items-center rounded-1'>
                <div className='w-30px h-30px rounded-1 text-capitalize fs-6 d-flex justify-content-center align-items-center bg-wafRed'>
                  {sample.waf.comparison.applicationSecurity[0].grade}
                </div>
                {`${sample.waf.comparison.applicationSecurity[0].percent}%`}
              </div>
            </div>
            <div className='col-3 p-3 px-0 pe-2 d-flex flex-column justify-content-start'>
              <div className='bg-gray-400 p-2 py-0 h-40px d-flex align-items-center rounded-1'>
                {intl.formatMessage({id: 'True-positive tests passed'})}
              </div>

              <div className='bg-wafSuccessLight mt-3 fw-bolder p-2 pe-5 py-0 h-40px d-flex justify-content-between align-items-center rounded-1'>
                <div className='w-30px h-30px rounded-1 text-capitalize fs-6 d-flex justify-content-center align-items-center bg-wafSuccess'>
                  {sample.waf.comparison.applicationSecurity[1].grade}
                </div>
                {`${sample.waf.comparison.applicationSecurity[1].percent}%`}
              </div>
            </div>
            <div className='col-3 p-3 px-0 pe-2 d-flex flex-column justify-content-between'>
              <div className='bg-gray-400 p-2 py-0 h-40px d-flex align-items-center rounded-1'>
                {intl.formatMessage({id: 'Grade'})}
              </div>

              <div className='bg-wafRedLight mt-3 fw-bolder p-2 pe-5 py-0 h-40px d-flex justify-content-between align-items-center rounded-1'>
                <div className='w-30px h-30px rounded-1 text-capitalize fs-6 d-flex justify-content-center align-items-center bg-wafRed'>
                  {sample.waf.comparison.applicationSecurity[2].grade}
                </div>
                {`${sample.waf.comparison.applicationSecurity[2].percent}%`}
              </div>
            </div>
          </div>
        </div>

        <div className='w-100 m-0 pe-3 '>
          <div className='w-100 d-flex fs-5 fw-bold bg-gray-400 rounded-1 mb-4 p-2 pt-1 pb-1 align-items-center'>
            <div className='w-50 p-1 ps-2 ms-5 h-auto'> {intl.formatMessage({id: 'Payload'})} </div>
            <div className='w-25 ms-3 d-flex p-1 h-auto'> {intl.formatMessage({id: 'Status'})} </div>
            <div className='w-25 p-1 h-auto d-flex justify-content-center'> {intl.formatMessage({id: 'Placeholder'})} </div>
            <div className='w-25 p-1 ms-2 h-auto d-flex justify-content-center'> {intl.formatMessage({id: 'Case'})} </div>
          </div>
        </div>
        {/* end titles */}
        <div className={`w-100 h-100 overflow-auto pe-3 m-0`}>
          {sample.waf.scope?.map((item, index) => {
            // const {issues} = item
            return (
              <div
                className={`w-100 m-0 p-0 h-auto bg-gray-200 rounded-1 d-flex justify-content-between align-items-center ${
                  index === sample.waf.scope.length - 1 ? null : 'mb-3'
                }`}
              >
                <div className='w-100 d-flex p-2 pt-1 pb-1 align-items-center'>
                  <div className='w-50 p-1 ps-2 ms-5 h-auto text-break'> {item.payload} </div>
                  <div className='w-25 d-flex ms-3 align-items-center p-1 h-auto'>
                    {item.status}
                  </div>
                  <div className='w-25 p-1 ms-2 h-auto d-flex justify-content-center'>
                    {' '}
                    {item.placeholder}{' '}
                  </div>
                  <div className='w-25 p-1 ms-2 h-auto d-flex justify-content-center'>
                    {item.cases}
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default WafPage
