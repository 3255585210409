import {useNavigate} from 'react-router-dom'
import ColumnChart from './ColumnChart'
import InfoIcon from '../../images/InfoIcon'
import {Tooltip as ReactTooltip} from 'react-tooltip'
import Arr001 from '../../components/Arr001'
import { useIntl } from 'react-intl'
interface Props {
  id: number
  address: string
  total_vulnerabilities: number
  vulnerabilities_over_time: {x: string[]; y: number[]}
}
const DefaultData = [
  {
    id: 4992,
    address: 'sub2.targetB.com',
    total_vulnerabilities: 54,
    vulnerabilities_over_time: {x: ['2023-06-17'], y: [54]},
  },
  {
    id: 4993,
    address: 'portal.sub2.targetB.com',
    total_vulnerabilities: 10,
    vulnerabilities_over_time: {x: ['2023-06-17'], y: [10]},
  },
  {
    id: 7686,
    address: 'cdn.targetC.com',
    total_vulnerabilities: 3,
    vulnerabilities_over_time: {x: ['2023-06-17'], y: [3]},
  },
  {
    id: 7687,
    address: 'bulk.targetC.com',
    total_vulnerabilities: 14,
    vulnerabilities_over_time: {x: ['2023-06-17'], y: [14]},
  },
  {
    id: 7688,
    address: 'team.targetC.com',
    total_vulnerabilities: 3,
    vulnerabilities_over_time: {x: ['2023-06-17'], y: [3]},
  },
]
const AssetsRisk = ({newTechnologies = DefaultData}: {newTechnologies: Props[]}) => {
  const navigate = useNavigate()
  const intl = useIntl()
  return (
    <div className='col-4' style={{height: 270}}>
      <div className={`card card-body h-100 bg-body`}>
        {/* start title */}
        <div className='d-flex flex-row justify-content-between align-items-center pt-2 pb-5'>
          <div className='d-flex flex-row align-items-center'>
            <h3 className='card-title align-items-start flex-column   m-0'>{intl.formatMessage({id: 'Assets at Risk'})}</h3>
            <span data-tooltip-id='my-tooltip-assetsAtRisk' data-tooltip-content={intl.formatMessage({id: "Assets at Risk tooltip"})}>
              <InfoIcon />
            </span>
            <ReactTooltip id='my-tooltip-assetsAtRisk' style={{maxWidth:250,fontSize:12 ,textAlign:'center'}} />
          </div>
          <button
            className={`col-auto border border-0  bg-body p-0 m-0`}
            onClick={() => {
              navigate('/assets')
            }}
          >
           {intl.formatMessage({id: 'View All'})} <Arr001 fill={'#fff'} className={'image_change_theme'} />
          </button>
        </div>
        {/* start body */}
        <div className={`w-100 h-100 overflow-auto pe-2 m-0`}>
          <div className='w-100 d-flex bg-secondary rounded-1 mb-4 p-2 pt-1 pb-1 justify-content-between align-items-center'>
            <div className='w-200px p-1  h-auto'> {intl.formatMessage({id: 'Address'})} </div>
            <div className='w-150px p-1 ms-2 d-flex justify-content-center border-2 border border-dark border-top-0 border-bottom-0 h-auto'>
            {intl.formatMessage({id: 'Issues'})}
              
            </div>
            <div className='w-100 p-1 ms-2 d-flex justify-content-center h-auto'>
            {intl.formatMessage({id: 'Risk over time'})}
            </div>
          </div>
          {newTechnologies?.map((item, index) => {
            return (
              <div
                key={item?.id}
                className={`d-flex flex-row w-100 h-70px bg-secondary p-2 border border-box m-0 align-items-center rounded-1 ${
                  index === newTechnologies?.length - 1 ? null : 'mb-3'
                } `}
              >
                <div className='w-200px p-1 text-primary text-truncate h-auto'>{item?.address}</div>
                <div className='w-150px p-1 ms-2 h-auto  d-flex justify-content-center'>
                  {item?.total_vulnerabilities}
                </div>
                <div className='w-100 p-4 pb-11 ms-2 h-auto  d-flex justify-content-center'>
                  {item?.vulnerabilities_over_time?.y ? (
                    <ColumnChart data={item?.vulnerabilities_over_time?.y || [2, 6, 8, 4]} />
                  ) : (
                    '-'
                  )}
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default AssetsRisk
