import React from 'react'
import { useIntl } from 'react-intl'

export default function Footer ({list = []}: {list: any[]}) {
  const intl = useIntl()
  return (
    <>
      <hr />
      <div className='card_footer flex-wrap'>
        {list.map((item, index) => (
          <div key={index} style={{width:'49%'}}>
            {item?.icon}
            <div>
              <span>{item?.name}</span>
              <strong>{intl.formatMessage({id: item?.value})}</strong>
            </div>
          </div>
        ))}
      </div>
    </>
  )
}
