import {FC, createContext, useContext, useState} from 'react'
import {WithChildren} from '../helpers'

const I18N_CONFIG_KEY =  'i18nConfig'

type Props = {
  selectedLang: 'en' |'fa'
  dir: 'ltr' | 'rtl'
}
const initialState: Props = {
  selectedLang: 'en',
  dir: 'ltr',
}
interface PropsType {
  lang:Props,
  setLang: React.Dispatch<React.SetStateAction<Props>>
}
function getConfig (): Props {
  const ls = localStorage.getItem(I18N_CONFIG_KEY)
  if (ls) {
    try {
      return JSON.parse(ls) as Props
    } catch (er) {
      console.error(er)
    }
  }
  return initialState
}

// Side effect
export function setLanguage (lang: string, dir: string) {
  localStorage.setItem(I18N_CONFIG_KEY, JSON.stringify({selectedLang: lang, dir: dir}))
  // window.location.reload()
}

export const I18nContext = createContext<PropsType|null>(null)

const useLang = () => {
  return useContext(I18nContext)
}
const useDirection = () => {
  const dir = useContext(I18nContext)
  if (dir) {
    return dir
  } else {
    return 'ltr'
  }
}

const MetronicI18nProvider: FC<WithChildren> = ({children}) => { 
  const [lang, setLang] = useState(getConfig())
  console.log(lang)
  return <I18nContext.Provider value={{lang,setLang}}>{children}</I18nContext.Provider>
}

export {MetronicI18nProvider, useLang, useDirection}
