/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import { useFormik} from 'formik'
import axios from 'axios'
import {Dispatch} from 'react'
import {useIntl} from 'react-intl'

const initialValues = {
  email: '',
  password: '',
  confirmPassword: '',
}
interface fetchLoginValueProps {
  email: string
  password: string
  confirmPassword: string
}
/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

export function SignUp () {
  const [loading, setLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const [password, setPassword] = useState('')
  const intl = useIntl()
  const loginSchema = Yup.object().shape({
    email: Yup.string()
      .email(intl.formatMessage({id: 'Wrong email format'}))
      .min(3, intl.formatMessage({id: 'Minimum 3 symbols'}))
      .max(50, intl.formatMessage({id: 'Maximum 50 symbols'}))
      .required(intl.formatMessage({id: 'Email is required'})),
    password: Yup.string()
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
        'Password must contain at least one uppercase, one lowercase, one special character, and one number with a minimum of eight characters'
      )
      .required('Password is required'),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password'), ''], 'Passwords must match')
      .required('Confirm Password is required'),
  })
  const fetchSignUp = async (
    values: fetchLoginValueProps,
    setStatus: Dispatch<any>,
    setSubmitting: Dispatch<any>
  ) => {
    setStatus('')
    try {
      const data = await axios.post(
        `${process.env.REACT_APP_API_ENDPOINT}/auth/users/`,
        {
          email: values.email,
          password: values.password,
        },
        {withCredentials: false}
      )
      if (data?.status >= 200 && data.status < 300) {
        setStatus("You have successfully registered,\n please confirm your email")
        setIsError(false)
      } else {
        // console.error(`error in login \n`, data)
        setStatus('The si detail is incorrect')
        setIsError(true)

      }
      setLoading(false)
    } catch (e:any) {
      console.log('aaa', e?.message)
      setLoading(false)
      setSubmitting(false)
      setStatus( e?.message||'The sign up detail is incorrect')
        setIsError(true)

    } finally {
      setLoading(false)
    }
  }
  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: (
      values: fetchLoginValueProps,
      {setStatus, setSubmitting}: {setStatus: Dispatch<any>; setSubmitting: Dispatch<any>}
    ) => {
      setLoading(true)
      fetchSignUp(values, setStatus, setSubmitting)
    },
  })
  
  return (
    <form
      className='w-100 form'
      onSubmit={formik.handleSubmit}
      noValidate
      id='kt_login_signup_form'
    >

      {formik.status ? (
        <div className={`mb-lg-15 alert ${isError? 'alert-danger':'alert-success'}  rounded-pill`}>
          <div className='font-weight-bold alert-text' >{formik.status}</div>
        </div>
      ) : (
        <div className='d-flex justify-content-center flex-column align-items-center mb-10 p-8 rounded'>
          <h1>Sign up To Admin</h1>
          <span className='opacity-75'>Enter your details to Sign up </span>
        </div>
      )}

      <div className='mb-10 fv-row'>
        <input
          placeholder='Email'
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control h-auto text-white placeholder-white opacity-70 bg-dark-o-70 rounded-pill border-0 py-4 px-8 mb-5',
            {'is-invalid': formik.touched.email && formik.errors.email},
            {
              'is-valid': formik.touched.email && !formik.errors.email,
            }
          )}
          type='email'
          name='email'
          autoComplete='off'
          style={{opacity: 0.7, backgroundColor: 'rgba(24, 28, 50, 0.28)'}}
        />
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container text-center text-danger'>
            <span role='alert'>{formik.errors.email}</span>
          </div>
        )}
      </div>

      <div className='mb-10 fv-row'>
        <input
          type='password'
          placeholder='Password'
          autoComplete='off'
          {...formik.getFieldProps('password')}
          onChange={(e) => {
            setPassword(e.target.value)
            formik.setFieldValue('password', e.target.value)
          }}
          value={password}
          className={clsx(
            'form-control h-auto text-white placeholder-white opacity-70 bg-dark-o-70 rounded-pill border-0 py-4 px-8 mb-5 ',
            {
              'is-invalid': formik.touched.password && formik.errors.password,
            },
            {
              'is-valid': formik.touched.password && !formik.errors.password,
            }
          )}
          style={{opacity: 0.7, backgroundColor: 'rgba(24, 28, 50, 0.28)'}}
        />
        <PasswordStrengthIndicator password={password} />
      </div>
      <div className='mb-10 fv-row'>
        <input
          type='password'
          placeholder='Confirm password'
          autoComplete='off'
          {...formik.getFieldProps('confirmPassword')}
          className={clsx(
            'form-control h-auto text-white placeholder-white opacity-70 bg-dark-o-70 rounded-pill border-0 py-4 px-8 mb-5 ',
            {
              'is-invalid': formik.touched.confirmPassword && formik.errors.confirmPassword,
            },
            {
              'is-valid': formik.touched.confirmPassword && !formik.errors.confirmPassword,
            }
          )}
          style={{opacity: 0.7, backgroundColor: 'rgba(24, 28, 50, 0.28)'}}
        />
        {formik.touched.confirmPassword && formik.errors.confirmPassword && (
          <div className='fv-plugins-message-container  text-center text-danger'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.confirmPassword}</span>
            </div>
          </div>
        )}
      </div>
      <div className='form-group d-flex flex-wrap justify-content-between align-items-center px-8'>
        <Link to='/auth/login' id='kt_login_forgot' className='text-white font-weight-bold'>
          I have an account{' '}
        </Link>
      </div>

      <div className='text-center mt-10'>
        <button
          type='submit'
          id='kt_sign_in_submit'
          className='btn btn-pill border border-white rounded-pill btn-outline-white font-weight-bold  px-15 py-3'
          disabled={loading || !formik.isValid}
          style={{opacity: 0.9}}
        >
          {!loading && <span className='indicator-label'>Sign up</span>}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Please wait...
              <span className='spinner-border-sm spinner-border align-middle ms-2'></span>
            </span>
          )}
        </button>

        {/* begin::Separator */}
        {/* <div className='mb-5 fw-bolder text-center text-muted text-uppercase'>or</div> */}
        {/* end::Separator */}

        {/* begin::Google link */}
        {/* <a href='#' className='btn-flex flex-center mb-5 w-100 btn btn-lg btn-light'>
          <img
            alt='Logo'
            src={toAbsoluteUrl('/media/svg/brand-logos/google-icon.svg')}
            className='h-20px me-3'
          />
          Continue with Google
        </a> */}
        {/* end::Google link */}

        {/* begin::Google link */}
        {/* <a href='#' className='btn-flex flex-center mb-5 w-100 btn btn-lg btn-light'>
          <img
            alt='Logo'
            src={toAbsoluteUrl('/media/svg/brand-logos/facebook-4.svg')}
            className='h-20px me-3'
          />
          Continue with Facebook
        </a> */}
        {/* end::Google link */}

        {/* begin::Google link */}
        {/* <a href='#' className='btn-flex flex-center w-100 btn btn-lg btn-light'>
          <img
            alt='Logo'
            src={toAbsoluteUrl('/media/svg/brand-logos/apple-black.svg')}
            className='h-20px me-3'
          />
          Continue with Apple
        </a> */}
        {/* end::Google link */}
      </div>
    </form>
  )
}
function PasswordStrengthIndicator ({password}: {password: string}) {
  const requirements = [
    {
      label: 'Password Uppercase',
      condition: /[A-Z]/.test(password),
    },
    {
      label: 'Password Special Characters',
      condition: /[@$!%*?&]/.test(password),
    },
    {
      label: 'Password Lowercase',
      condition: /[a-z]/.test(password),
    },
    {
      label: 'Password is Eight in Length',
      condition: password.length >= 8,
    },
    {
      label: 'Password Has at Least One Number',
      condition: /\d/.test(password),
    },
  ]

  return (
    <div>
      {requirements.map((req, index) => (
        <div key={index} style={{color: 'red', display: req.condition ? 'none' : 'block'}}>
          {req.label}
        </div>
      ))}
    </div>
  )
}
