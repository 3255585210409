import React, {useEffect, useState} from 'react'

import {useNavigate} from 'react-router-dom'
import ReactPaginate from 'react-paginate'
import axios from 'axios'
import {useDispatch} from 'react-redux'
import {useSelector} from 'react-redux'
import {useIntl} from 'react-intl'
import useErrorHandling from '../../../../Utils/useErrorHandling'
import {RootState} from '../../../../setup/redux/Store'
import useGetFilterOptionsDomains from '../../../../Utils/useGetFilterOptionsDomains'
import {FilterSelectedAssets, SetDataAsset} from '../../../modules/auth'
import PageLoading from '../../loading/PageLoading'

import {portsData} from './Data'
import Check from '../../images/Ckeck'
import Close from '../../images/Close'


export default function Ports () {
  const [loading, setLoading] = useState(false)
  const [open, setOpen] = useState<number | null>(null)
  const errorHandling = useErrorHandling()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const intl = useIntl()
  const filterOptionDomains = useSelector(
    (state: RootState) => state?.root?.filterOption?.assets.options
  )
  const selected = useSelector((state: RootState) => state?.root?.filterOption?.assets.selected)
  const getFilterOptionsDomains = useGetFilterOptionsDomains()
  const handlePageClick = (event: {selected: number}) => {
    // fetchDomainsData(`?page=${event.selected + 1}`)
  }
  function selectedHandel (items: string, item: string) {
    const copy = structuredClone(selected)
    if (selected[items] && selected[items].length && selected[items].includes(item)) {
      copy[items] = copy[items].filter((it: string | number) => it !== item)
    } else {
      if (copy[items]?.length) {
        copy[items].push(item)
      } else {
        copy[items] = [item]
      }
    }
    dispatch(FilterSelectedAssets(copy))
    if (process.env.REACT_APP_MODE !== 'demo') {
    //   fetchDomainsData('?page=1', copy)
    }
  }
  function createPropsFilter (props?: any) {
    if (props) {
      const keys = Object.keys(props)
      const text = keys.map((key) => {
        if (props[key] && props[key].length) {
          return `&${key}=${props[key].join(',')}`
        } else {
          return ''
        }
      })

      const prop = text.join('')

      return prop
    } else {
      const keys = Object.keys(selected)
      const text = keys.map((key) => {
        if (selected[key] && selected[key].length) {
          return `&${key}=${selected[key].join(',')}`
        } else {
          return ''
        }
      })

      const prop = text.join('')

      return prop
    }
  }
  const fetchDomainsData = async (query = '?page=1', filterProps?: any) => {
    // setLoading(true)
    // try {
    //   const data = await axios.get(
    //     `${process.env.REACT_APP_API_ENDPOINT}/assets/${query}${createPropsFilter(filterProps)}`
    //   )
    //   errorHandling(data.status) //logout when invalid token && redirect 404
    //   if (data.status >= 200 && data.status < 300) {
    //     dispatch(SetDataAsset(data.data))
    //   } else {
    //     console.error(
    //       `error in get data from ${process.env.REACT_APP_API_ENDPOINT}/assets/${query}${filterProps} \n`,
    //       data
    //     )
    //   }
    //   setLoading(false)
    // } catch (e) {
    //   console.log(e)
    //   setLoading(false)
    // }
  }

  useEffect(() => {
    // const nowDate = new Date().getTime()
    // if (assetData?.expireTime && assetData.expireTime >= nowDate) {
    // } else {
    //   fetchDomainsData()
    // }
    if (!filterOptionDomains && process.env.REACT_APP_MODE !== 'demo') {
      getFilterOptionsDomains()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return loading ? (
    <div className='w-100 h-400px'>
      <PageLoading />
    </div>
  ) : (portsData && portsData.length) ? (
    <>
      <div className='w-100  p-4 bg-body '>
        <h1>{intl.formatMessage({id: 'Ports'})}</h1>
        <div className='w-100  d-flex pt-5 position-relative'>
          <div className=' w-25   border-end border-start border-gray pe-4 d-flex flex-column gap-4 '>
            {filterOptionDomains ? (
              Object.keys(filterOptionDomains)?.map((items: string, index: number) => {
                return (
                  <div key={index} className='d-flex flex-column'>
                    <h5 className='m-0 ' style={{textTransform: 'capitalize'}}>
                      {intl.formatMessage({id: items})}
                    </h5>
                    {!!filterOptionDomains[items]?.length ? (
                      filterOptionDomains[items]
                        .slice(
                          0,
                          filterOptionDomains[items]?.length > 10
                            ? open === index
                              ? filterOptionDomains[items]?.length - 1
                              : 10
                            : filterOptionDomains[items]?.length
                        )
                        .map((item: any, ind: number) => (
                          <div>
                            <hr className='my-2 mx-0' />
                            <div
                              key={ind}
                              style={{cursor: 'pointer'}}
                              onClick={(e) => {
                                if (items === 'scores') {
                                  selectedHandel(items, item)
                                } else {
                                  selectedHandel(items, item.name)
                                }
                              }}
                              className='form-check form-check-custom form-check-solid form-check-sm  p-1 rounded item_hover '
                            >
                              <input
                                className='form-check-input'
                                type='checkbox'
                                readOnly
                                checked={
                                  items === 'scores'
                                    ? selected[items]?.includes(item)
                                    : selected[items]?.includes(item.name)
                                }
                              />
                              <div className='form-check-label w-100 d-flex justify-content-between mx-2'>
                                <span>{items === 'scores' ? item : item.name}</span>
                                <span>{items === 'scores' ? '' : item.count}</span>
                              </div>
                            </div>
                          </div>
                        ))
                    ) : (
                      <span>N/A</span>
                    )}
                    {filterOptionDomains[items]?.length - 1 > 10 &&
                      (index !== open ? (
                        <span
                          style={{alignSelf: 'center', cursor: 'pointer'}}
                          onClick={() => {
                            setOpen(index)
                          }}
                        >
                          {intl.formatMessage({id: 'show more'})}
                        </span>
                      ) : (
                        <span
                          style={{alignSelf: 'center', cursor: 'pointer'}}
                          onClick={() => {
                            setOpen(null)
                          }}
                        >
                          {intl.formatMessage({id: 'show less'})}
                        </span>
                      ))}
                  </div>
                )
              })
            ) : (
              <PageLoading />
            )}
          </div>
          <div className=' w-75 ps-3'>
            <div className={`w-100  overflow-visible  m-0 pb-8`}>
              <div className='d-flex flex-column align-items-center'>
                {/* start title */}
                <div className='w-100 pe-3 d-flex h-70px flex-row justify-content-between align-items-start pt-2'>
                  <input
                    type='text'
                    className='form-control w-75  form-control-solid text-gray-700'
                    placeholder={intl.formatMessage({id: 'Search'})}
                  />
                </div>
                {/* start body */}
                {/* start titles */}
                <div className='w-100 m-0  '>
                  <div className='w-100 d-flex fs-5 fw-bold bg-gray-400 rounded-1 mb-4 p-2 pt-1 pb-1 justify-content-between align-items-center'>
                    <div className='w-25 p-1 h-auto'>
                      <div className='w-100  h-auto d-flex justify-content-start align-items-center pointer'>
                        <span>{intl.formatMessage({id: 'Port Number'})}</span>
                      </div>
                    </div>
                    <div className='w-25 p-1 h-auto d-flex justify-content-start'>
                      <div className='w-100  h-auto d-flex justify-content-start align-items-center pointer'>
                        {' '}
                        <span>{intl.formatMessage({id: 'Asset'})}</span>{' '}
                      </div>
                    </div>
                    <div className='w-25 d-flex justify-content-start p-1 ms-2 h-auto'>
                      {intl.formatMessage({id: 'Protocol'})}
                    </div>
                    <div className='w-25 p-1 ms-2 h-auto d-flex justify-content-start'>
                      <div className='w-100 h-auto d-flex justify-content-start align-items-center pointer'>
                        {' '}
                        <span>{intl.formatMessage({id: 'Service'})}</span>{' '}
                      </div>
                    </div>
                    <div className='w-80px p-1 ms-2 h-auto d-flex justify-content-center pe-3'>
                      {' '}
                      {intl.formatMessage({id: 'Status'})}{' '}
                    </div>
                  </div>
                </div>
                {/* end titles */}
                {portsData.map((item, index: number) => (
                  <div
                    key={index}
                    className={`w-100 m-0 py-2 p-0 h-auto bg-gray-200 rounded-1 d-flex justify-content-start align-items-center mb-3`}
                  >
                    <div className='w-100 d-flex p-2 pt-1 pb-1 justify-content-between align-items-center'>
                      <div className='w-25 p-1   h-auto'> {item.port_number} </div>
                      <div className='w-25 p-1 h-auto d-flex justify-content-start'>
                        {item.asset}
                      </div>
                      <div className='w-25 d-flex justify-content-start align-items-center p-1 h-auto text-uppercase'>
                        {item.protocol}
                      </div>
                      <div className='w-25 p-1 ms-2 h-auto d-flex justify-content-start'>
                        {item.service}
                      </div>
                      <div className='w-80px p-1 ms-2 h-auto d-flex justify-content-center pe-3'>
                        {item.status ? (
                          <Check className=' svg-icon-2x svg-icon-success ' />
                        ) : (
                          <Close className='  svg-icon-2x  svg-icon-danger ' />
                        )}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <div className='d-flex justify-content-end bg-body mt-5 pe-4 rounded-bottom'>
                <ReactPaginate
                  nextLabel={`${intl.formatMessage({id: 'Next'})} >`}
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={3}
                  marginPagesDisplayed={2}
                  //   pageCount={assetData?.data?.total_pages}
                  //   forcePage={assetData?.data?.current_page - 1}
                  pageCount={1}
                  forcePage={0}
                  previousLabel={`< ${intl.formatMessage({id: 'Previous'})}`}
                  pageClassName='page-item'
                  pageLinkClassName='page-link'
                  previousClassName='page-item'
                  previousLinkClassName='page-link'
                  nextClassName='page-item'
                  nextLinkClassName='page-link'
                  breakLabel='...'
                  breakClassName='page-item'
                  breakLinkClassName='page-link'
                  containerClassName='pagination'
                  activeClassName='active'
                  renderOnZeroPageCount={null}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  ) : null
}
