import React, {memo} from 'react'
interface Props {
  className?: string
  title: string
  children: React.ReactNode
}
const CardProvider = memo(({className = '', title = '', children}: Props) => {
  return (
    <div className={`d-flex flex-column gap-2 align-items-center p-2 bg-body card ${className}`}>
      <h3 className=' text-capitalize'>{title}</h3>
      <div
      className='w-100 text-center h-100'
      >{children}</div>
    </div>
  )
})
export default CardProvider
