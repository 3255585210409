import {useNavigate} from 'react-router-dom'
import sample from '../../sample'
import InfoIcon from '../../images/InfoIcon'
import {Tooltip as ReactTooltip} from 'react-tooltip'
import Arr001 from '../../components/Arr001'
import ConvertDate from '../../../../Utils/ConvertDate'
import { useIntl } from 'react-intl'

interface Props {
  id: number
  severity: string
  tag: string
  timestamp: string
  title: string
}

const RecentVulnerabilities = ({recentIssues}: {recentIssues: Props[]}) => {
  const navigate = useNavigate()
  const intl = useIntl()
  const {
    tooltips: {recentVulnerabilities},
  } = sample
  return (
    <div className='col-6 h-400px'>
      <div className={`card card-body h-100 bg-body`}>
        {/* start title */}
        <div className='d-flex flex-row justify-content-between align-items-center pt-2 pb-5'>
          <div className='d-flex flex-row align-items-center'>
            <h3 className='card-title align-items-start flex-column   m-0'>
              {intl.formatMessage({id: 'Recent Vulnerabilities'})}
            </h3>

            <span data-tooltip-id={'my-tooltip-recent'} data-tooltip-content={intl.formatMessage({id: "Recent Vulnerabilities tooltip"})}>
              <InfoIcon />
            </span>
            <ReactTooltip id={'my-tooltip-recent'} style={{maxWidth:250,fontSize:12 ,textAlign:'center'}}/>
          </div>
          <button
            className={`col-auto border border-0  bg-body p-0 m-0`}
            onClick={() => {
              navigate('/vulnerabilities')
            }}
          >
            {intl.formatMessage({id: 'View All'})} <Arr001 fill={'#fff'} className={'image_change_theme'} />
          </button>
        </div>
        {/* start body */}
        <div className={`w-100 h-100 overflow-auto pe-2 m-0`}>
          {recentIssues?.map((issue, index) => (
            <div
              key={issue?.id}
              className={`d-flex flex-row w-100 h-55px bg-secondary p-2 border border-box m-0 align-items-center rounded-3 ${
                index === recentIssues.length - 1 ? null : 'mb-3'
              } `}
            >
              <div
                className={`w-30px h-30px bg-body   rounded-1 d-flex justify-content-center align-items-center text-uppercase p-0 m-0`}
              >
                {issue?.tag}
              </div>
              <div className='w-1px h-75 gx-0 bg-dark me-3 ms-3' />
              <div
                className={`w-15px h-15px rounded-3 border border-2 border-${`i${issue?.severity}`}`}
              />

              <div className={`me-auto ms-2 mw-200px text-truncate`}>{issue?.title}</div>
              <div className={`w-auto p-0 m-0 text-uppercase`}>
                <ConvertDate date={issue?.timestamp} />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default RecentVulnerabilities
