import React from 'react'
import './Card.scss'
import {useNavigate} from 'react-router-dom'
export default function CardProvider ({link, children}: {link: string ,children:React.ReactNode}) {
  const navigate = useNavigate()
  console.log(link)
  return (
    <div
      className='card_custom bg-secondary card '
      onClick={() => {
        navigate(link)
      }}
      style={{height:'fit-content', width:'100%'}}
    >
      {children}
    </div>
  )
}
