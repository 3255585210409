export const portsData = [
    {
        port_number:22,
        asset:'10.10.10.10',
        protocol:'tcp',
        service:'ssh',
        status:false
    },
    {
        port_number:80,
        asset:'11.11.11.11',
        protocol:'udp',
        service:'http',
        status:true
    },
    {
        port_number:8080,
        asset:'20.20.20.20',
        protocol:'tcp',
        service:'https',
        status:false
    },
    {
        port_number:444,
        asset:'10.10.10.10',
        protocol:'tcp',
        service:'ssh',
        status:false
    },
    {
        port_number:3000,
        asset:'10.10.10.10',
        protocol:'tcp',
        service:'ssh',
        status:false
    },
    {
        port_number:8022,
        asset:'10.10.10.10',
        protocol:'tcp',
        service:'ssh',
        status:false
    },
    {
        port_number:4030,
        asset:'10.10.10.10',
        protocol:'tcp',
        service:'ssh',
        status:false
    },
    {
        port_number:8000,
        asset:'10.10.10.10',
        protocol:'tcp',
        service:'ssh',
        status:false
    },
    {
        port_number:22,
        asset:'10.10.10.10',
        protocol:'tcp',
        service:'ssh',
        status:false
    },
    {
        port_number:80,
        asset:'10.10.10.10',
        protocol:'tcp',
        service:'ssh',
        status:false
    },
    {
        port_number:88,
        asset:'10.10.10.10',
        protocol:'tcp',
        service:'ssh',
        status:false
    },
]