import React, {useEffect, useState} from 'react'

import {useNavigate} from 'react-router-dom'
import ReactPaginate from 'react-paginate'
import axios from 'axios'
import {useDispatch} from 'react-redux'
import {useIntl} from 'react-intl'
import useErrorHandling from '../../../../Utils/useErrorHandling'
import PageLoading from '../../loading/PageLoading'
import ConvertDate from '../../../../Utils/ConvertDate'
import Sort from '../../images/Sort'
import { DnsData } from './Data'

export default function DNS () {
  const [loading, setLoading] = useState(false)
  const [open, setOpen] = useState<number | null>(null)
  const errorHandling = useErrorHandling()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const intl = useIntl()
  const handlePageClick = (event: {selected: number}) => {
    // fetchDomainsData(`?page=${event.selected + 1}`)
  }

  const fetchDomainsData = async (query = '?page=1', filterProps?: any) => {
    // setLoading(true)
    // try {
    //   const data = await axios.get(
    //     `${process.env.REACT_APP_API_ENDPOINT}/assets/${query}${createPropsFilter(filterProps)}`
    //   )
    //   errorHandling(data.status) //logout when invalid token && redirect 404
    //   if (data.status >= 200 && data.status < 300) {
    //     dispatch(SetDataAsset(data.data))
    //   } else {
    //     console.error(
    //       `error in get data from ${process.env.REACT_APP_API_ENDPOINT}/assets/${query}${filterProps} \n`,
    //       data
    //     )
    //   }
    //   setLoading(false)
    // } catch (e) {
    //   console.log(e)
    //   setLoading(false)
    // }
  }

  useEffect(() => {
    // const nowDate = new Date().getTime()
    // if (assetData?.expireTime && assetData.expireTime >= nowDate) {
    // } else {
    //   fetchDomainsData()
    // }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return loading ? (
    <div className='w-100 h-400px'>
      <PageLoading />
    </div>
  ) : (DnsData && DnsData.length) ? (
    <>
      <div className='w-100  p-4 bg-body '>
        <h1>{intl.formatMessage({id: 'DNS Records'})}</h1>
        <div className='w-100  d-flex pt-5 position-relative'>

          <div className=' w-100 '>
            <div className={`w-100  overflow-visible  m-0 pb-8`}>
              <div className='d-flex flex-column align-items-center'>

                {/* start titles */}
                <div className='w-100 m-0  '>
                  <div className='w-100 d-flex fs-5 fw-bold bg-gray-400 rounded-1 mb-4 p-2 pt-1 pb-1 justify-content-between align-items-center'>
                    <div className='w-50 p-1 h-auto'>
                      <div className='w-100  h-auto d-flex justify-content-start align-items-center pointer'>
                        <span>{intl.formatMessage({id: 'Record'})}</span>
                      </div>
                    </div>
                    <div className='w-25 p-1 h-auto d-flex justify-content-start'>
                      <div className='w-100  h-auto d-flex justify-content-start align-items-center pointer'>
                        {' '}
                        <span>{intl.formatMessage({id: 'Value'})}</span>{' '}
                      </div>
                    </div>
                    <div className='w-25 d-flex justify-content-start p-1 ms-2 h-auto'>
                      {intl.formatMessage({id: 'Type'})}
                    </div>
                    <div className='w-25 p-1 ms-2 h-auto d-flex justify-content-start'>
                      <div className='w-100 h-auto d-flex justify-content-start align-items-center pointer'>
                        {' '}
                        <span>{intl.formatMessage({id: 'Domain'})}</span>{' '}
                      </div>
                    </div>
                    <div className='w-25 p-1 ms-2 h-auto d-flex justify-content-center pe-3'>
                      {' '}
                      {intl.formatMessage({id: 'Detected'})}{' '}
                    </div>
                  </div>
                </div>
                {/* end titles */}
                {DnsData.map((item, index: number) => (
                  <div
                    key={index}
                    className={`w-100 m-0 p-0 h-auto bg-gray-200 rounded-1 d-flex justify-content-start align-items-center mb-3`}
                  >
                    <div className='w-100 d-flex p-2 pt-1 pb-1 justify-content-between align-items-center'>
                      <div className='w-50 p-1   h-auto'> {item.record} </div>
                      <div className='w-25 p-1 h-auto d-flex justify-content-start'>
                        {item.value}
                      </div>
                      <div className='w-25 d-flex justify-content-start align-items-center p-1 h-auto text-uppercase'>
                        {item.type}
                      </div>
                      <div className='w-25 p-1 ms-2 h-auto d-flex justify-content-start'>
                        {item.domain}
                      </div>
                      <div className='w-25 p-1 ms-2 h-auto d-flex justify-content-center pe-3'>
                        <ConvertDate date={item.detected}/>
                        
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <div className='d-flex justify-content-end bg-body  pe-4 rounded-bottom'>
                <ReactPaginate
                  nextLabel={`${intl.formatMessage({id: 'Next'})} >`}
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={3}
                  marginPagesDisplayed={2}
                  //   pageCount={assetData?.data?.total_pages}
                  //   forcePage={assetData?.data?.current_page - 1}
                  pageCount={1}
                  forcePage={0}
                  previousLabel={`< ${intl.formatMessage({id: 'Previous'})}`}
                  pageClassName='page-item'
                  pageLinkClassName='page-link'
                  previousClassName='page-item'
                  previousLinkClassName='page-link'
                  nextClassName='page-item'
                  nextLinkClassName='page-link'
                  breakLabel='...'
                  breakClassName='page-item'
                  breakLinkClassName='page-link'
                  containerClassName='pagination'
                  activeClassName='active'
                  renderOnZeroPageCount={null}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  ) : null
}
