import React, {memo} from 'react'
import ColumnChart from './ColumnChart'
import InfoIcon from '../../images/InfoIcon'
import {Tooltip} from 'react-tooltip'
import { useIntl } from 'react-intl'
interface Props {
  icon: JSX.Element
  header: string
  subHeader: string
  bgColor: string
  subColor: string
  chartColors: string[]
  data: number[]
  categories: string[]
  tooltip?: string
}
const Card = memo(
  ({
    icon = <i className='bi bi-bookmarks-fill fs-2x' />,
    header = '55',
    subHeader = 'SAP UI Progress',
    bgColor = 'bg-dark',
    subColor = ' ',
    chartColors = ['#96bded'],
    data = [10, 20, 30, 10],
    categories = ['Jan', 'Feb', 'Mar', 'Apr', 'Oct', 'Jul'],
    tooltip = 'Tooltip...',
  }: Props) => {
    const intl = useIntl()
    console.log(intl.formatMessage({id: tooltip}))
    return (
      <div className='d-flex flex-column h-150px col-3'>
        <div className={`h-100px card card-body ${bgColor}`}>
          <div className='row d-flex flex-row'>
            <div className='col-6'>
              {icon}
              <div className={`${subColor} fw-bolder fs-1 mt-6 mb-8`}>{header}</div>
            </div>

            <div className='col-6 h-100 mt-1'>
              <ColumnChart categories={categories} height={80} data={data} colors={chartColors} />
            </div>
          </div>
          <div className='d-flex fw-bold text-igray'>
            {intl.formatMessage({id: subHeader})}
            <span data-tooltip-id={'my-tooltip'+subHeader}  data-tooltip-content={intl.formatMessage({id: tooltip})}>
              <InfoIcon />
            </span>
            <Tooltip id={'my-tooltip'+subHeader} style={{maxWidth:250,fontSize:12 ,textAlign:'center'}}/>
          </div>
        </div>
      </div>
    )
  }
)

export default Card
