/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {KTSVG} from '../../../../_metronic/helpers'
import {useIntl} from 'react-intl'
import Map from './Map'
import {IPsDetails} from './Data'

interface Props {
  setId: React.Dispatch<React.SetStateAction<number | null>>
  id: number | null
}

export const DetailsDrawer = ({id, setId}: Props) => {
  const intl = useIntl()

  return (
    <>
      {/* begin::Demos drawer */}
      <div
        id='kt_engage_filters'
        className={`bg-body drawer drawer-end ${id !== null ? 'drawer-on' : ''}`}
        data-kt-drawer-width="{default:'350px', 'lg': '375px'}"
        style={{minWidth: 400, maxWidth: 400}}
      >
        <>
          {/* begin::Card  */}
          <div className='card shadow-none w-100' style={{height: 'fit-content'}}>
            {/* begin::Header */}
            <div className='card-header' id='kt_explore_header' style={{minHeight: 'unset'}}>
              <div className='card-toolbar'>
                <button
                  type='button'
                  className='btn btn-sm btn-icon explore-btn-dismiss me-n5'
                  id='kt_engage_filters_close'
                  onClick={() => {
                    setId(null)
                  }}
                >
                  <KTSVG
                    path='/media/icons/duotune/arrows/arr061.svg'
                    className='svg-icon-2 image_change_theme2'
                  />
                </button>
              </div>
            </div>
            <div className='p-5'>
              <Map lat={IPsDetails.lat} lng={IPsDetails.lng} />
              <h2 className='mt-5'>{IPsDetails.ip}</h2>
              <span className=' text-gray-500'>IP Address</span>
              <h4 className='mt-10'>Information</h4>
              <hr />
              <div className='d-flex align-items-center justify-content-between gap-5'>
                <h6 className=' text-gray-500'>Status</h6>
                <strong className=' text-capitalize'>{IPsDetails.status}</strong>
              </div>
              <hr />
              <div className='d-flex align-items-center justify-content-between gap-5'>
                <h6 className=' text-gray-500'>Location</h6>
                <strong className=' text-capitalize'>{IPsDetails.loc}</strong>
              </div>
              <hr />
              <div className='d-flex align-items-center justify-content-between gap-5'>
                <h6 className=' text-gray-500'>Ping</h6>
                <strong className=' text-capitalize'>{IPsDetails.ping}</strong>
              </div>
              <hr />
              <div className='d-flex align-items-center justify-content-between gap-5'>
                <h6 className=' text-gray-500'>Reverse DNS (PTR)</h6>
                <strong className=' '>{IPsDetails.reverse}</strong>
              </div>
              <hr />
              <div className='d-flex align-items-center justify-content-between gap-5'>
                <h6 className=' text-gray-500'>Cloud Account</h6>
                <strong className=' text-capitalize'>{IPsDetails.cloud_account}</strong>
              </div>
              <hr />
              <div className='d-flex align-items-center justify-content-between gap-5'>
                <h6 className=' text-gray-500'>Cloud ID</h6>
                <strong className=''>{IPsDetails.cloud_id}</strong>
              </div>
              <hr />
              <div className='d-flex align-items-center justify-content-between gap-5'>
                <h6 className=' text-gray-500'>ASN</h6>
                <strong className=''>{IPsDetails.ans}</strong>
              </div>
            </div>
            {/* end::Header */}
          </div>
          {/* begin::Card */}
        </>
      </div>
      {/* end::Demos drawer */}
    </>
  )
}
