import React from 'react'

import DonutChart from './DonutChart'
import sample from '../../sample'
import InfoIcon from '../../images/InfoIcon'
import {Tooltip as ReactTooltip} from 'react-tooltip'
import { useIntl } from 'react-intl'
const DefaultData = [7, 1, 51, 8, 1]
const IssueByStatus = ({data = DefaultData}: {data: number[]}) => {
  const intl = useIntl()
  const {
    tooltips: {AssetsByGroup},
  } = sample
  return (
    <div className='col-4' style={{height: 270}}>
      <div className={`card card-body h-100 bg-body`}>
        <div className='d-flex flex-row align-items-center'>
          <h3 className='card-title align-items-start flex-column   m-0'>{intl.formatMessage({id: 'Assets By Grade'})}</h3>
          <span data-tooltip-id='my-tooltip-AssetsByGroup' data-tooltip-content={intl.formatMessage({id: "Assets By Grade tooltip"})}>
            <InfoIcon />
          </span>
          <ReactTooltip id='my-tooltip-AssetsByGroup' style={{maxWidth:250,fontSize:12 ,textAlign:'center'}}/>
        </div>
        <DonutChart data={data} />
      </div>
    </div>
  )
}

export default IssueByStatus
