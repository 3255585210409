import React, {memo, useEffect, useState} from 'react'
import {useFormik} from 'formik'
import clsx from 'clsx'

import {KTSVG} from '../../../../_metronic/helpers'

import axios from 'axios'
import useErrorHandling from '../../../../Utils/useErrorHandling'
import PageLoading from '../../loading/PageLoading'
import {useIntl} from 'react-intl'
import './CustomInput.css'
interface Value {
  id?: number
  target: string | null
  workflow: number | null
  description: string | null
  status: string | null
  start_time: string | null
  rate: string | null
}
const initialValues: Value = {
  id: 0,
  target: '',
  workflow: 0,
  description: '',
  status: '',
  start_time: '',
  rate: '',
}

const Modal = memo(() => {
  const [error, setError] = useState('')
  const [value1, setValue1] = useState('20')
  const [value2, setValue2] = useState('20')
  const [value3, setValue3] = useState('20')
  const [value4, setValue4] = useState('20')
  const errorHandling = useErrorHandling()
  const intl = useIntl()
  function clearForm () {
    setError('')
  }

  return (
    <>
      <div
        className='modal fade '
        id='kt_modal_edit'
        style={{backdropFilter: 'blur(3px)'}}
        onClick={(e) => {
          e.stopPropagation()
          e.preventDefault()
          clearForm()
        }}
      >
        <div
          className='modal-dialog'
          style={{minWidth: '50%'}}
          onClick={(e) => {
            e.stopPropagation()
          }}
        >
          <div className='modal-content'>
            <div className='modal-header'>
              <h3 className='modal-title'> Dos Running</h3>
              {<p style={{color: 'red'}}>{error}</p>}
              <div
                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                data-bs-dismiss='modal'
                aria-label='Close'
                onClick={clearForm}
              >
                <KTSVG
                  path='/media/icons/duotune/arrows/arr061.svg'
                  className='svg-icon-2 image_change_theme2'
                />
              </div>
            </div>

            <div className='modal-body'>
              <label htmlFor='customRange1' className='form-label'>
                Example range 1
              </label>
              <div className='d-flex align-items-center justify-content-between h-25px'>
                <input
                  type='range'
                  min='0'
                  max='100'
                  id='customRange1'
                  className='costume_rang'
                  step='1'
                  value={value1}
                  onChange={(e) => {
                    setValue1(e.target.value)
                  }}
                ></input>
                <div
                  className='w-50px h-100 text-center bg-gray-300 border-bottom border-gray-900 '
                  style={{fontSize: 15}}
                >
                  {value1}
                </div>
              </div>
              <hr />
              <label htmlFor='customRange2' className='form-label'>
                Example range 2
              </label>
              <div className='d-flex align-items-center justify-content-between h-25px'>
                <input
                  type='range'
                  min='0'
                  max='100'
                  id='customRange2'
                  className='costume_rang'
                  step='1'
                  value={value2}
                  onChange={(e) => {
                    setValue2(e.target.value)
                  }}
                ></input>
                <div
                  className='w-50px h-100 text-center bg-gray-300 border-bottom border-gray-900 '
                  style={{fontSize: 15}}
                >
                  {value2}
                </div>
              </div>
              <hr />
              <label htmlFor='customRange3' className='form-label'>
                Example range 3
              </label>
              <div className='d-flex align-items-center justify-content-between h-25px'>
                <input
                  type='range'
                  min='0'
                  max='100'
                  id='customRange3'
                  className='costume_rang'
                  step='1'
                  value={value3}
                  onChange={(e) => {
                    setValue3(e.target.value)
                  }}
                ></input>
                <div
                  className='w-50px h-100 text-center bg-gray-300 border-bottom border-gray-900 '
                  style={{fontSize: 15}}
                >
                  {value3}
                </div>
              </div>
              <hr />
              <label htmlFor='customRange4' className='form-label'>
                Example range 4
              </label>
              <div className='d-flex align-items-center justify-content-between h-25px'>
                <input
                  type='range'
                  min='0'
                  max='100'
                  id='customRange4'
                  className='costume_rang'
                  step='1'
                  value={value4}
                  onChange={(e) => {
                    setValue4(e.target.value)
                  }}
                ></input>
                <div
                  className='w-50px h-100 text-center bg-gray-300 border-bottom border-gray-900 '
                  style={{fontSize: 15}}
                >
                  {value4}
                </div>
              </div>
              <hr />

              <button className='btn btn-primary w-25'>Submit</button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
})
export default Modal
