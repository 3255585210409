import React from 'react'

export default function ConvertDate({date}:{date:string}) {
    function formatDateString(dateString: string): string {
        const date = new Date(dateString);
        
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        
        return `${year}/${month}/${day} at ${hours}:${minutes}`;
      }
  return (<div className='Date'>
    <span >
      {formatDateString(date)}
    </span>
  </div>
  )
}
