export const IPsData = [
    {
        ip: 'www.example.com',
        dns: 'ce2-18-99-147us-est.2component.amazon.com',
        live: true,
        mod: false,
        id: 1
    },
    {
        ip: 'www.example.com',
        dns: 'ce2-18-99-147us-est.2component.amazon.com',
        live: true,
        mod: false,
        id: 2
    },
    {
        ip: 'www.example.com',
        dns: 'ce2-18-99-147us-est.2component.amazon.com',
        live: true,
        mod: false,
        id: 3
    },
    {
        ip: 'www.example.com',
        dns: 'ce2-18-99-147us-est.2component.amazon.com',
        live: true,
        mod: false,
        id: 4
    },
    {
        ip: 'www.example.com',
        dns: 'ce2-18-99-147us-est.2component.amazon.com',
        live: true,
        mod: false,
        id: 5
    },
    {
        ip: 'www.example.com',
        dns: 'ce2-18-99-147us-est.2component.amazon.com',
        live: true,
        mod: false,
        id: 6
    },
    {
        ip: 'www.example.com',
        dns: 'ce2-18-99-147us-est.2component.amazon.com',
        live: true,
        mod: false,
        id: 7
    },
    {
        ip: 'www.example.com',
        dns: 'ce2-18-99-147us-est.2component.amazon.com',
        live: true,
        mod: false,
        id: 8
    },
    {
        ip: 'www.example.com',
        dns: 'ce2-18-99-147us-est.2component.amazon.com',
        live: true,
        mod: false,
        id: 9
    },
    {
        ip: 'www.example.com',
        dns: 'ce2-18-99-147us-est.2component.amazon.com',
        live: true,
        mod: false,
        id: 10
    },
]
export const IPsDetails =
{
    lat:51.505,
    lng:-0.09,
    ip: '10.134.53.100',
    status: 'offline',
    ping: '',
    loc: 'IR',
    reverse: 'ce2-18-99-147us-est.2component.amazon.com',
    cloud_account: 'Services Prod',
    cloud_id: 'i-782398',
    ans: 'AS14820'
}
