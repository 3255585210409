import React, {useState, useEffect} from 'react'
// import sample from '../../sample'
import BarChartAsset from './BarChartAsset'
import PageLoading from '../../loading/PageLoading'
import axios from 'axios'
import {useSearchParams} from 'react-router-dom'
import useErrorHandling from '../../../../Utils/useErrorHandling'
import {SSL_DETAILS_DATA} from '../../../modules/auth/redux/DEMO_DATA'
import { useIntl } from 'react-intl'
import EmptyPage from '../../emptyPage/EmptyPage'

const getColor = (item: string) => {
  switch (item.toUpperCase()) {
    case 'A': {
      return '#1fa055'
    }
    case 'A+': {
      return '#1fa055'
    }
    case 'B': {
      return '#255a8f'
    }
    case 'C': {
      return '#ffff4d'
    }
    case 'D': {
      return '#ff66a3'
    }
    case 'F': {
      return '#FF0000'
    }
    case 'T': {
      return '#FF0000'
    }
    case 'M': {
      return '#FF0000'
    }
    case 'N': {
      return '#FF0000'
    }

    default:
      return ''
  }
}

const SSLDetails = () => {
  const [loading, setLoading] = useState(true)
  const [sslDetailData, setSslDetailData] = useState<any>()
  const [hostName, setHostName] = useState('')
  const [searchParams] = useSearchParams()
  const errorHandling = useErrorHandling()
  const id = searchParams.get('id')
  const intl = useIntl()
  const fetchSslData = async () => {
    setLoading(true)
    try {
      const data = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/ssl/${id}/`)
      errorHandling(data.status) //logout when invalid token && redirect 404
      if (data.status >= 200 && data.status < 300) {
        setHostName(data?.data?.host)
        setSslDetailData(data?.data?.data)
      } else {
        console.error(
          `error in get data from ${process.env.REACT_APP_API_ENDPOINT}/ssl/${id} \n`,
          data
        )
      }
      setLoading(false)
    } catch (e) {
      console.log(e)
      setLoading(false)
    }
  }
  useEffect(() => {
    if (process.env.REACT_APP_MODE === 'demo') {
      setSslDetailData(SSL_DETAILS_DATA?.data)
      setHostName(SSL_DETAILS_DATA?.host)
    } else {
      fetchSslData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <>
      {loading ? (
        <PageLoading />
      ) : sslDetailData && hostName ? (
        <div className='row d-flex flex-row mt-5'>
          <div className='w-100 h-100'>
            <div className='card card-body bg-gray-100 mb-7'>
              <div className='w-100 d-flex mb-1 pb-4 align-items-center pt-2 border-gray-400 border-3 border-bottom'>
                <div className='  fs-1'>{`Summary Report for ${hostName}`}</div>
              </div>
              <div className='row mt-8 mb-8'>
                <div className='col-5 d-flex flex-column justify-content-center align-items-center'>
                  <div className='mb-3'>{intl.formatMessage({id: 'Overall Rating'})}</div>
                  <div
                    className='w-150px h-150px d-flex justify-content-center align-items-center rounded-2 text-capitalize'
                    style={{
                      fontSize: '7em',
                      fontWeight: 'bold',
                      backgroundColor: sslDetailData?.rating[3]?.finding
                        ? getColor(sslDetailData?.rating[3]?.finding)
                        : getColor(''),
                    }}
                  >
                    {sslDetailData?.rating[3]?.finding}
                  </div>
                </div>
                <div className='col-7'>
                  <BarChartAsset data={sslDetailData?.rating} />
                </div>
              </div>

              {sslDetailData?.info?.map((item: any) => (
                <div
                  className={`w-100 d-flex justify-content-center fs-6 my-2 px-8 rounded-2 border-gray-400 border-2 p-3 border bg-body`}
                >
                  {item.finding}
                </div>
              ))}
            </div>

            {sslDetailData?.report?.map((item: any, index: number) => {
              const {title} = item
              const row = item.detail
              return (
                <div className={`card card-body bg-gray-100 mb-7 px-5`} key={index}>
                  <div className='w-100 d-flex my-2 pb-4 flex-row justify-content-between align-items-start pt-2 border-gray-400 border-3 border-bottom'>
                    <div className='  fs-1'>{title}</div>
                  </div>
                  {row?.map((it: any, ind: number) => (
                    <div
                      key={ind}
                      className={`w-100 my-2 px-8 border-gray-400 border-1 pb-3 border-bottom `}
                    >
                      <div className='w-100 d-flex fs-6 fw-bold justify-content-between align-items-center'>
                        <div className='col-4 p-1 h-auto'> {it.title} </div>
                        <div className='col-8 p-1 ps-2 ms-5 h-auto'> {it.finding} </div>
                      </div>
                    </div>
                  ))}
                </div>
              )
            })}
          </div>
        </div>
      ) : <EmptyPage/>}
    </>
  )
}

export default SSLDetails
