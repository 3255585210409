import React, {useState, useEffect} from 'react'

import {useNavigate, useSearchParams} from 'react-router-dom'
import axios from 'axios'
import PageLoading from '../../loading/PageLoading'
import Arr001 from '../../components/Arr001'
import useErrorHandling from '../../../../Utils/useErrorHandling'
import ConvertDate from '../../../../Utils/ConvertDate'
import { useIntl } from 'react-intl'

const ScanDetails = () => {
  const [loading, setLoading] = useState(true)
  const [ScanDetailData, setScanDetailData] = useState<any>()
  const [searchParams] = useSearchParams()
  const errorHandling = useErrorHandling()
  const navigate = useNavigate()
  const intl = useIntl()
  const id = searchParams.get('id')
  useEffect(() => {
    setLoading(true)
    const fetchScanDetailData = async () => {
      try {
        const data = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/assets/${id}/`)
        errorHandling(data.status) //logout when invalid token && redirect 404
        if (data.status >= 200 && data.status < 300) {
          setScanDetailData(data.data)
        } else {
          console.error(
            `error in get data from ${process.env.REACT_APP_API_ENDPOINT}/assets/${id} \n`,
            data
          )
        }
        setLoading(false)
      } catch (e) {
        console.log(e)
        setLoading(false)
      }
    }

    fetchScanDetailData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      {loading ? (
        <PageLoading />
      ) : (
        <>
          <div
            className='modal fade'
            id='exampleModal'
            tabIndex={-1}
            aria-labelledby='exampleModalLabel'
            aria-hidden='true'
          >
            <div className='modal-dialog modal-lg'>
              <div className='modal-content'>
                <div className='modal-header'>
                  <button
                    type='button'
                    className='btn-close'
                    data-bs-dismiss='modal'
                    aria-label='Close'
                  />
                </div>
                <div
                  className='modal-body min-w-500px min-h-500px'
                  style={{
                    background: `url(${ScanDetailData?.screen_shot})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'contain',
                    backgroundPosition: 'center',
                  }}
                >
                  {/* <img width={'100%'} height={'100%'} alt='screenshot' src={screen_shot} /> */}
                </div>
              </div>
            </div>
          </div>

          <div className={`card card-body row d-flex flex-row h-100 bg-body p-0 m-2`}>
            <div className='row d-flex col-8 gx-0 p-3'>
              <div className='d-flex h-auto justify-content-between align-items-start pt-2'>
                <h3 className=' '>{ScanDetailData.host}</h3>
              </div>
              <div className='w-100 p-2 d-flex align-items-center justify-content-between'>
                <div className='w-200px p-1 d-flex align-items-center text-gray-800 h-auto'>
                  <div
                    className={`w-30px h-30px bg-gray-600 text-white rounded-1 d-flex justify-content-center align-items-center p-1 me-3`}
                  >
                    {ScanDetailData?.tag}
                  </div>
                  {ScanDetailData?.targets}
                </div>
                <div className='d-flex flex-row align-items-center'>
                  <span className='text-white fs-6'>last seen:</span>
                  <span className='text-gray-800 fs-7 ms-3'>
                    <ConvertDate date={ScanDetailData.timestamp} />
                  </span>
                </div>
              </div>

              <div className={`h-100 overflow-auto p-2 pe-3 mt-5 pt-5`}>
                <ul className='nav nav-tabs nav-line-tabs mb-5 mt-5 fs-6'>
                  <li className='nav-item'>
                    <a className='nav-link active show' data-bs-toggle='tab' href='#tab_1'>
                      Details
                    </a>
                  </li>

                  <li className='nav-item '>
                    <a className='nav-link' data-bs-toggle='tab' href='#tab_2'>
                      Technologies
                    </a>
                  </li>

                  <li className='nav-item '>
                    <a className='nav-link' data-bs-toggle='tab' href='#tab_3'>
                      {intl.formatMessage({id: 'Vulnerabilities'})}
                    </a>
                  </li>
                </ul>
                <div
                  className='tab-content  p-3 bg-mh_black rounded-1 mt-5 overflow-auto'
                  id='myTabContent'
                >
                  <div className='tab-pane fade active show p-0' id='tab_1' role='tabpanel'>
                    <div className='w-100 row col-12 gx-0'>
                      <div className='col-6 p-2'>
                        <div className='p-2 rounded-2'>
                          <div className='w-100 d-flex flex-column p-4 rounded-2'>
                            <div className='fs-2 text-white text-capitalize'>IPv4</div>
                            <div className='fs-2 text-white min-h-85px'>
                              {ScanDetailData?.ipv4?.map((it: any, id: number) => (
                                <div
                                  key={it}
                                  className={`text-gray-700 fs-6 ms-5 ${
                                    id === 0 ? 'mt-4' : 'mt-1'
                                  }`}
                                >
                                  {it}
                                </div>
                              ))}
                            </div>
                          </div>

                          <div className='w-100 d-flex flex-column bg-secondary mt-5 p-4 rounded-2'>
                            <div className='fs-2 text-white '>OpenPorts</div>
                            <div className='fs-2 text-white min-h-85px'>
                              {ScanDetailData?.port?.map((it: any, id: number) => (
                                <div
                                  key={it}
                                  className={`text-gray-700 fs-6 ms-5 ${
                                    id === 0 ? 'mt-4' : 'mt-1'
                                  }`}
                                >
                                  {it}
                                </div>
                              ))}
                            </div>
                          </div>

                          <div className='w-100 d-flex flex-column mt-5 p-4 rounded-2'>
                            <div className='fs-2 text-white '>CIDRS</div>
                            <div className='fs-2 text-white min-h-85px'>
                              {ScanDetailData?.cidrs?.map((it: any, id: number) => (
                                <div
                                  key={it}
                                  className={`text-gray-800 fs-6 ms-5 ${
                                    id === 0 ? 'mt-4' : 'mt-1'
                                  }`}
                                >
                                  {it}
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='col-6 p-2'>
                        <div className='p-2 rounded-2'>
                          <div className='w-100 d-flex flex-column p-4 rounded-2'>
                            <div className='fs-2 text-white '>IPv6</div>
                            <div className='fs-2 text-white min-h-85px'>
                              {ScanDetailData?.ipv6?.map((it: any, id: number) => (
                                <div
                                  key={it}
                                  className={`text-gray-700 fs-6 ms-5 ${
                                    id === 0 ? 'mt-4' : 'mt-1'
                                  }`}
                                >
                                  {it}
                                </div>
                              ))}
                            </div>
                          </div>

                          <div className='w-100 d-flex flex-column bg-secondary mt-5 p-4 rounded-2'>
                            <div className='fs-2 text-white '>Location</div>
                            <div className='fs-2 text-white min-h-85px'>
                              {ScanDetailData?.loc?.map((it: any, id: number) => (
                                <div
                                  key={it}
                                  className={`text-gray-700 fs-6 ms-5 ${
                                    id === 0 ? 'mt-4' : 'mt-1'
                                  }`}
                                >
                                  {it}
                                </div>
                              ))}
                            </div>
                          </div>

                          <div className='w-100 d-flex flex-column mt-5 p-4 rounded-2'>
                            <div className='fs-2 text-white '>ASNS</div>
                            <div className='fs-2 text-white min-h-85px'>
                              {ScanDetailData?.asns?.map((it: any, id: number) => (
                                <div
                                  key={it}
                                  className={`text-gray-800 fs-6 ms-5 ${
                                    id === 0 ? 'mt-4' : 'mt-1'
                                  }`}
                                >
                                  {it}
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className='tab-pane fade' id='tab_2' role='tabpanel'>
                    <div className='d-flex flex-column p-3'>
                      <div className='w-50 d-flex mt-3 flex-column fs-6 text-gray-900 col-3 rounded-2 p-4'>
                        <div className='fs-2 text-white '>Detected Technologies</div>
                        {ScanDetailData?.techs?.map((item: any, index: number) => (
                          <span key={`item: ${item}`}>{`${index + 1}: ${item}`}</span>
                        ))}
                      </div>
                    </div>
                  </div>

                  <div className='tab-pane fade' id='tab_3' role='tabpanel'>
                    {/* start titles */}
                    <div className='w-100 m-0 p-0'>
                      <div className='w-100 d-flex fs-5 fw-bold bg-gray-400 rounded-1 mb-4 p-1 align-items-center'>
                        <div className='w-50 p-1 ps-2 h-auto'> {intl.formatMessage({id: 'Title'})} </div>
                        <div className='w-25 p-1 ps-4 ms-3 h-auto d-flex justify-content-center align-items-center'>
                          {' '}
                          {intl.formatMessage({id: 'Severity'})}{' '}
                        </div>
                        <div className='w-25 p-1 ms-3 h-auto d-flex justify-content-center align-items-center'>
                          {' '}
                          {intl.formatMessage({id: 'Last Seen'})}{' '}
                        </div>

                        <div className='w-50px p-0 ms-2 h-auto d-flex justify-content-center pe-3'>
                          {' '}
                          {intl.formatMessage({id: 'View'})}{' '}
                        </div>
                      </div>
                    </div>
                    {/* end titles */}
                    {ScanDetailData?.vulnerabilities_list?.map(
                      (vulnerability: any, index: number) => {
                        return (
                          <div
                            key={vulnerability.id}
                            className={`w-100 m-0 me-2 p-0 h-auto bg-gray-200 rounded-1 d-flex align-items-center ${
                              index === ScanDetailData.vulnerabilities_list.length - 1
                                ? null
                                : 'mb-3'
                            }`}
                          >
                            <div className='w-100 d-flex p-1 align-items-center'>
                              <div className='w-50 p-1 h-auto d-flex align-items-center'>
                                {' '}
                                {vulnerability.title}{' '}
                              </div>
                              <div className='w-25 p-1 ms-3 h-auto d-flex flex-row align-items-center text-capitalize'>
                                <div className='w-50 d-flex justify-content-end'>
                                  <div
                                    className={`w-15px h-15px me-2 rounded-3 border-i${vulnerability.severity} border border-2 `}
                                  />
                                </div>
                                {vulnerability.severity}
                              </div>
                              <div className='w-25 p-1 ms-3 h-auto d-flex justify-content-center align-items-center'>
                                <ConvertDate date={vulnerability.timestamp} />
                              </div>
                              <button
                                className={`w-50px border border-0 text-white bg-gray-200 p-0 ms-2 pe-3 d-flex-justify-content-center`}
                                onClick={() => {
                                  navigate({
                                    pathname: '/vulnerability-details',
                                    search: `?id=${vulnerability.id}`,
                                  })
                                }}
                              >
                                {/* <KTSVG
                                path='/media/icons/duotune/arrows/arr001.svg'
                                className='svg-icon svg-icon-2x ms-2 svg-icon-white'
                              /> */}
                                <Arr001 fill={'#fff'} className={'image_change_theme'} />
                              </button>
                            </div>
                          </div>
                        )
                      }
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className={`col-4 m-0 p-3`}>
              <div className='row d-flex flex-row p-1 pe-3 h-50 '>
                <div className='d-flex flex-column bg-mh_black h-100'>
                  <div className='w-100 h-40px fs-4 text-white pt-3 border-bottom border-2 border-gray-500'>
                    {intl.formatMessage({id: 'Associated Vulnerabilities'})}
                  </div>

                  <div className='w-100 d-flex flex-row h-40px pt-3 pb-3'>
                    <div className='px-3 py-3 d-flex flex-row align-items-center bg-secondary rounded-3'>
                      <span>{`${ScanDetailData.total_vulnerabilities}`}</span>
                    </div>
                    <div className={`w-2px h-100 bg-white mx-3`} />
                    <div className='px-2 py-3 d-flex flex-row align-items-center bg-secondary rounded-3'>
                      <div className={`w-15px h-15px rounded-3 bg-icritical me-2`} />
                      <span>{ScanDetailData.vulnerabilities.critical}</span>
                    </div>
                    <div className='px-2 py-3 ms-2 d-flex flex-row align-items-center bg-secondary rounded-3'>
                      <div className={`w-15px h-15px rounded-3 bg-ihigh me-2`} />
                      <span>{ScanDetailData.vulnerabilities.high}</span>
                    </div>
                    <div className='px-2 py-3 ms-2 d-flex flex-row align-items-center bg-secondary rounded-3'>
                      <div className={`w-15px h-15px rounded-3 bg-imedium me-2`} />
                      <span>{ScanDetailData.vulnerabilities.medium}</span>
                    </div>
                    <div className='px-2 py-3 ms-2 d-flex flex-row align-items-center bg-secondary rounded-3'>
                      <div className={`w-15px h-15px rounded-3 bg-ilow me-2`} />
                      <span>{ScanDetailData.vulnerabilities.low}</span>
                    </div>
                    <div className='px-2 py-3 ms-2 d-flex flex-row align-items-center bg-secondary rounded-3'>
                      <div className={`w-15px h-15px rounded-3 bg-iinfo me-2`} />
                      <span>{ScanDetailData.vulnerabilities.info}</span>
                    </div>
                  </div>

                  <div className='d-flex flex-column overflow-auto mt-1 p-2 pt-3 pb-3'>
                    {ScanDetailData?.recent_vulnerabilities
                      ?.slice(0, 5)
                      ?.map((vulnerability: any, index: number, arr: any[]) => {
                        // const {issues} = sample
                        // const issue = issues.find((item) => item.id == issueId)

                        return (
                          <div
                            key={`assets detail list - ${vulnerability.id}`}
                            className={`d-flex flex-column w-100 bg-secondary p-3 border border-box m-0 rounded-3 ${
                              index === arr.length - 1 ? null : 'mb-3'
                            }`}
                          >
                            <div className='w-100 d-flex flex-row align-items-center justify-content-between h-30px text-capitalize'>
                              <div className='w-100 d-flex flex-row'>
                                <div
                                  className={`w-15px h-15px rounded-3 border border-2 me-2 border-${
                                    vulnerability.severity && `i${vulnerability.severity}`
                                  }`}
                                />
                                {vulnerability.severity}
                                <div className='w-1px min-h-20px gx-0 bg-dark me-3 ms-3' />
                                <div className={`w-auto p-0 m-0 text-uppercase text-gray-700`}>
                                  <ConvertDate date={vulnerability.timestamp} />
                                </div>
                              </div>

                              <button
                                className={`col-auto border border-0 text-white bg-secondary p-0 m-0`}
                                onClick={() => {
                                  navigate({
                                    pathname: '/vulnerability-details',
                                    search: `?id=${vulnerability.id}`,
                                  })
                                }}
                              >
                                {/* <KTSVG
                                  path='/media/icons/duotune/arrows/arr001.svg'
                                  className='svg-icon svg-icon-2x ms-2 svg-icon-white'
                                /> */}
                                <Arr001 fill={'#fff'} className={'image_change_theme'} />
                              </button>
                            </div>
                            <div className={`w-100 h-auto d-flex align-items-center p-0`}>
                              {vulnerability.title}
                            </div>
                          </div>
                        )
                      })}
                  </div>
                </div>
              </div>

              <div className='row d-flex flex-row p-1 pe-3 h-50'>
                <div className='d-flex flex-column h-100 bg-mh_black'>
                  <div className='w-100 h-40px fs-4 text-white pt-3 border-bottom border-2 border-gray-500'>
                   {intl.formatMessage({id: 'ScreenShot'})}
                  </div>
                  <div
                    data-bs-toggle='modal'
                    data-bs-target='#exampleModal'
                    className='w-100 h-100 p-3 d-flex justify-content-center align-items-center pt-3 cursor-pointer'
                    style={{
                      background: `url(${ScanDetailData.screen_shot})`,
                      backgroundRepeat: 'no-repeat',
                      backgroundSize: 'contain',
                      backgroundPosition: 'center',
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  )
}

export default ScanDetails
