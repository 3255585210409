const overview = [
    {
        title: 'Requests (period)',
        value: '68.8 K',
        class: ''
    },
    {
        title: 'success',
        value: '97.0%',
        class: ''
    },
    {
        title: 'conns',
        value: '314',
        class: ''
    },
    {
        title: 'reqs',
        value: '768',
        class: ''
    },
    {
        title: 'success (2m)',
        value: '92.6%',
        class: ''
    },
    {
        title: 'HTTP 1/2xx (2m)',
        value: '496',
        class: 'text-success'
    },
    {
        title: 'HTTP 3xx (2m)',
        value: '200',
        class: 'text-primary'
    },
    {
        title: 'HTTP 4xx (2m)',
        value: '72',
        class: 'text-warning'
    },
    {
        title: 'HTTP 3xx (2m)',
        value: '0',
        class: 'text-danger'
    },
]
const httpRequestsIngress_LineChart = {
    x: ['2024/10/01', '2024/10/02', '2024/10/03', '2024/10/04', '2024/10/05', '2024/10/06', '2024/10/07', '2024/10/08', '2024/10/09', '2024/10/10'],
    y: [
        {
            name: 'data1',
            data: [800, 900, 190, 1100, 200, 1200, 144, 672, 312, 887]
        },
        {
            name: 'data2',
            data: [700, 100, 490, 1200, 700, 1200, 944, 172, 612, 987]
        },
        {
            name: 'data3',
            data: [900, 200, 290, 900, 600, 1200, 444, 372, 512, 887]
        },
        {
            name: 'data4',
            data: [600, 100, 90, 1100, 1000, 1200, 544, 772, 212, 487]
        },
        {
            name: 'data5',
            data: [100, 300, 590, 1000, 500, 1200, 644, 672, 912, 287]
        },
    ],
}
const httpStatusCode_LineChart = {
    x: ['2024/10/01', '2024/10/02', '2024/10/03', '2024/10/04', '2024/10/05', '2024/10/06', '2024/10/07', '2024/10/08', '2024/10/09', '2024/10/10'],
    y: [
        {
            name: 'data1',
            data: [800, 900, 190, 1100, 200, 1200, 144, 672, 312, 887]
        },
        {
            name: 'data2',
            data: [700, 100, 490, 1200, 700, 1200, 944, 172, 612, 987]
        },
        {
            name: 'data3',
            data: [900, 200, 290, 900, 600, 1200, 444, 372, 512, 887]
        },
        {
            name: 'data4',
            data: [600, 100, 90, 1100, 1000, 1200, 544, 772, 212, 487]
        },
        {
            name: 'data5',
            data: [100, 300, 590, 1000, 500, 1200, 644, 672, 912, 287]
        },
    ],
}
const totalHttpRequest_ColumnChart = {
    x: ['2024/10/01', '2024/10/02', '2024/10/03', '2024/10/04', '2024/10/05', '2024/10/06', '2024/10/07', '2024/10/08', '2024/10/09', '2024/10/10', '2024/10/11', '2024/10/12', '2024/10/13', '2024/10/14', '2024/10/15', '2024/10/16', '2024/10/17', '2024/10/18', '2024/10/19', '2024/10/20'],
    y: [
        800, 900, 190, 1100, 200, 1200, 144, 672, 312, 887, 800, 900, 190, 1100, 200, 1200, 144, 672, 312, 887, 800, 900, 190, 1100, 200, 1200, 144, 672, 312, 887, 800, 900, 190, 1100, 200, 1200, 144, 672, 312, 887, 800, 900, 190, 1100, 200, 1200, 144, 672, 312, 887
    ],
}
const httpStatusCode_tag = [
    {
        color: '#02694a',
        title: 'HTTP 200',
    },
    {
        color: '#255a8f',
        title: 'HTTP 404',
    },
    {
        color: '#9c8705',
        title: 'HTTP 400',
    },
    {
        color: '#c02407',
        title: 'HTTP 500',
    },
    {
        color: '#75041e',
        title: 'HTTP 505',
    },
]

const latencyAveragePercentiles_LineChart = {
    x: ['2024/10/01', '2024/10/02', '2024/10/03', '2024/10/04', '2024/10/05', '2024/10/06', '2024/10/07', '2024/10/08', '2024/10/09', '2024/10/10'],
    y: [
        {
            name: 'data1',
            data: [800, 900, 190, 1100, 200, 1200, 144, 672, 312, 887]
        },
        {
            name: 'data2',
            data: [700, 100, 490, 1200, 700, 1200, 944, 172, 612, 987]
        },
        {
            name: 'data3',
            data: [900, 200, 290, 900, 600, 1200, 444, 372, 512, 887]
        },
        {
            name: 'data4',
            data: [600, 100, 90, 1100, 1000, 1200, 544, 772, 212, 487]
        },
        {
            name: 'data5',
            data: [100, 300, 590, 1000, 500, 1200, 644, 672, 912, 287]
        },
    ],
}
const latencyAveragePercentiles_tag = [
    {
        color: '#02694a',
        value: '0.99',
        min:'0.01 s',
        max:'1 s',
        ave:'0.55 s'
    },
    {
        color: '#255a8f',
        value: '0.99',
        min:'0.01 s',
        max:'1 s',
        ave:'0.55 s'
    },
    {
        color: '#9c8705',
        value: '0.99',
        min:'0.01 s',
        max:'1 s',
        ave:'0.55 s'
    },
    {
        color: '#c02407',
        value: '0.99',
        min:'0.01 s',
        max:'1 s',
        ave:'0.55 s'
    },
    {
        color: '#75041e',
        value: 'Average',
        min:'0.01 s',
        max:'1 s',
        ave:'0.55 s'
    },
]

const newConnections_ColumnChart = {
    x: ['2024/10/01', '2024/10/02', '2024/10/03', '2024/10/04', '2024/10/05', '2024/10/06', '2024/10/07', '2024/10/08', '2024/10/09', '2024/10/10', '2024/10/11', '2024/10/12', '2024/10/13', '2024/10/14', '2024/10/15', '2024/10/16', '2024/10/17', '2024/10/18', '2024/10/19', '2024/10/20'],
    y: [
        800, 900, 190, 1100, 200, 1200, 144, 672, 312, 887, 800, 900, 190, 1100, 200, 1200, 144, 672, 312, 887, 800, 900, 190, 1100, 200, 1200, 144, 672, 312, 887, 800, 900, 190, 1100, 200, 1200, 144, 672, 312, 887, 800, 900, 190, 1100, 200, 1200, 144, 672, 312, 887
    ],
}
const totalConnection_LineChart = {
    x: ['2024/10/01', '2024/10/02', '2024/10/03', '2024/10/04', '2024/10/05', '2024/10/06', '2024/10/07', '2024/10/08', '2024/10/09', '2024/10/10'],
    y: [
        {
            name: 'data1',
            data: [800, 900, 190, 1100, 200, 1200, 144, 672, 312, 887]
        },

    ],
}
export {
    overview,
    httpRequestsIngress_LineChart,
    httpStatusCode_LineChart,
    httpStatusCode_tag,
    totalHttpRequest_ColumnChart,
    latencyAveragePercentiles_LineChart,
    latencyAveragePercentiles_tag,
    newConnections_ColumnChart,
    totalConnection_LineChart
}