import {FC,  useEffect, useState, ReactNode} from 'react'
import { useSelector} from 'react-redux'
import {LayoutSplashScreen} from '../../../../_metronic/layout/core'
import {RootState} from '../../../../setup/redux/Store'
import useGetUserData from '../../../../Utils/useGetUserData'


type Props = {children: ReactNode}

const AuthInit: FC<Props> = (props) => {
  const [showSplashScreen, setShowSplashScreen] = useState(true)
  const getUserData = useGetUserData()
  const user = useSelector((state: RootState) => state.root.user)

  useEffect(() => {
    if (user) {
      setShowSplashScreen(false)
    } else {
      getUserData().then(e=>{setShowSplashScreen(false)})
    }
    // eslint-disable-next-line
  }, [])

  return showSplashScreen ? <LayoutSplashScreen /> : <>{props.children}</>
}

export default AuthInit

// import {FC, useRef, useEffect, useState, ReactNode} from 'react'
// import {shallowEqual, useSelector, connect, useDispatch, ConnectedProps} from 'react-redux'
// import {LayoutSplashScreen} from '../../../../_metronic/layout/core'
// import * as auth from './AuthRedux'
// import { RootState } from '../../../../setup/redux/Store'
// // import {getUserByToken} from './AuthCRUD'

// const mapState = (state: RootState) => ({auth: state.root})
// const connector = connect(mapState, auth.actions)
// type PropsFromRedux = { children: ReactNode;}

// const AuthInit: FC<PropsFromRedux> = (props) => {
//   const didRequest = useRef(false)
//   const dispatch = useDispatch()
//   const [showSplashScreen, setShowSplashScreen] = useState(true)
//   const accessToken = useSelector<RootState>((auth) => auth.root.accessToken, shallowEqual)

//   // We should request user by authToken before rendering the application
//   useEffect(() => {
//     const requestUser = async () => {
//       try {
//         if (!didRequest.current) {
//           // const {data: user} = await getUserByToken(accessToken as string)
//           dispatch(
//             auth.UserLoaded({
//               api_token: '$2y$10$lzYGs3CVjxdlR2ERLfZOyezaXM8qXLGd5fHEkjoBmDxznEl.CvAdC',
//               created_at: '2022-07-14T11:37:39.000000Z',
//               email: 'admin@demo.com',
//               email_verified_at: '2022-07-14T11:37:39.000000Z',
//               first_name: 'German',
//               id: 2,
//               last_name: 'Stark',
//               updated_at: '2022-07-14T11:37:39.000000Z',
//             })
//           )
//         }
//       } catch (error) {
//         console.error(error)
//         if (!didRequest.current) {
//           dispatch(auth.Logout_())
//         }
//       } finally {
//         setShowSplashScreen(false)
//       }

//       return () => (didRequest.current = true)
//     }

//     if (accessToken) {
//       requestUser()
//     } else {
//       dispatch(auth.Logout_())
//       setShowSplashScreen(false)
//     }
//     // eslint-disable-next-line
//   }, [])

//   return showSplashScreen ? <LayoutSplashScreen /> : <>{props.children}</>
// }

// export default connector(AuthInit)
