
import { NavLink, Outlet} from 'react-router-dom'

import { useIntl } from 'react-intl'
import { ExploreSidebar } from './Data/ExploreSidebar'

export default function Explore () {
  const intl = useIntl()
  return (
    <div className='d-flex w-100  mt-5 overflow-auto'>
      <div className='w-100px overflow-auto  bg-gray-200' style={{minWidth:100}}>
      <div className='d-flex flex-column align-items-center gap-10 pt-10 p-4 bg-gray-200' >
        
        {ExploreSidebar?.map((item, ind) => (
          <NavLink
            className={({isActive}: {isActive: boolean}) =>
              isActive
                ? 'card_icon w-75px pb-2 h-auto card border border-3 border-primary'
                : 'card_icon w-75px pb-2 h-auto card'
            }
            to={item.name}
            key={ind}
          >
            <div className={' bg-body domain w-100 '}>
              {item.icon }
            </div>
            <span style={{fontSize: 10, textOverflow: 'hidden'}}>{intl.formatMessage({id: item.name})}</span>
          </NavLink>
        ))}
      </div>
      </div>
      <Outlet />
    </div>
  )
}
