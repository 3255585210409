import React from 'react'
// import ChartLine from '../../ChartLine'
import { useIntl } from 'react-intl'
import ChartLine from './ChartLine'

export default function Chart ({list = []}: {list: any[]}) {
  const intl = useIntl()
  return (
    <>
      <hr />
      <div className='d-flex justify-content-between gap-5' style={{padding:10}}>
        {list.map((item, ind) => (
          <div className='d-flex gap-2 align-center  w-50' key={ind}>
            <div className='d-flex flex-column'>
              <span>{intl.formatMessage({id: item.title})}</span>
              <strong style={{fontSize:18}}>{item.count}</strong>
            </div>
            <ChartLine data={item.data}/>
          </div>
        ))}
      </div>
    </>
  )
}
